import { configureStore } from '@reduxjs/toolkit';
import { createWrapper } from 'next-redux-wrapper';
import { filtersSlice } from './filters';
import { flatFiltersSlice } from './flatFilters';
import { whitelistSlice } from './whitelist';
import { generalSlice } from './general';
import { pageDataSlice } from './pageData';
import { collectionsSlice } from './collections';
import { ssoSlice } from '@/modules/sso/store/sso';
import { influencerReport } from '@/modules/influencerReport/store/influencerReport';
import { genreGlobalFiltersSlice } from '../modules/genre/store/genre';
import { campaignReport } from '../modules/report/store/campaignReport';
import { accountTracking } from '../modules/account-tracking/store/accountTracking';
import { contentDiscovery } from '../modules/content-discovery/store/contentDiscovery';

const makeStore = () =>
  configureStore({
    reducer: {
      [filtersSlice.name]: filtersSlice.reducer,
      [generalSlice.name]: generalSlice.reducer,
      [ssoSlice.name]: ssoSlice.reducer,
      [flatFiltersSlice.name]: flatFiltersSlice.reducer,
      [influencerReport.name]: influencerReport.reducer,
      [pageDataSlice.name]: pageDataSlice.reducer,
      [collectionsSlice.name]: collectionsSlice.reducer,
      [genreGlobalFiltersSlice.name]: genreGlobalFiltersSlice.reducer,
      [campaignReport.name]: campaignReport.reducer,
      [accountTracking.name]: accountTracking.reducer,
      [whitelistSlice.name]: whitelistSlice.reducer,
      [contentDiscovery.name]: contentDiscovery.reducer,
    },
    devTools: true,
  });

export const wrapper = createWrapper(makeStore);
