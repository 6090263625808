import Image from 'next/image';
import { useState } from 'react';

const DefaultImage = ({
  width,
  height,
  name,
  index = 0,
  fontSize,
  thumbnail,
  className,
  borderRadius,
  display,
  margin,
  border,
  minWidth,
  defaultImageClass,
  imageBorder,
}: any) => {
  const [showDefaultImage, setShowDefaultImage] = useState(false);

  const bgColors = [
    '#1abc9c',
    '#3498db',
    '#9b59b6',
    '#f1c40f',
    '#e67e22',
    '#e74c3c',
    '#f39c12',
    '#95a5a6',
    '#fab1a0',
    '#a29bfe',
    '#81ecec',
    '#fdcb6e',
    '#ff7675',
    '#f9ca24',
    '#badc58',
    '#c7ecee',
    '#7ed6df',
    '#95afc0',
    '#487eb0',
    '#fbc531',
    '#7f8fa6',
    '#747d8c',
    '#ff6b81',
    '#ff6348',
    '#ffa502',
    '#eccc68',
    '#a4b0be',
    '#f8a5c2',
    '#63cdda',
    '#cf6a87',
  ];

  const defaultImage = {
    display: display ? display : 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: width ? width + 'px' : '100%',
    height: height ? height + 'px' : '100%',
    backgroundColor: bgColors[index % 26],
    borderRadius: borderRadius ? borderRadius : null,
    border: border ? border : null,
    margin: margin ? margin : null,
    minWidth: minWidth ? minWidth : null,
  };

  const defaultImageLetter = {
    color: 'white',
    fontSize: fontSize,
    margin: '0',
    padding: '0',
  };

  function getFirstLetter(name: string) {
    if (name)
      for (let i = 0; i < name.length; i++) {
        if (name[i].match(/[a-z]/i)) {
          return name[i].toUpperCase();
        }
      }
    return '';
  }

  return (
    <>
      {!showDefaultImage && thumbnail && (
        <img
          className={className}
          src={thumbnail}
          alt={name ? name : 'default'}
          width={width || '100'}
          height={height ? height : '100'}
          onError={() => setShowDefaultImage(true)}
          style={imageBorder ? { border: imageBorder } : {}}
        />
      )}

      {(showDefaultImage || !thumbnail) && (
        <section
          id="defaultImage"
          style={defaultImage}
          className={defaultImageClass}
        >
          <p style={defaultImageLetter}>{getFirstLetter(name) || 'A'}</p>
        </section>
      )}
    </>
  );
};

export default DefaultImage;
