import style from '@/styles/ProUpgradeModal.module.css';
import { useDispatch } from 'react-redux';
import { updateToastStatus } from '@/store/general';
import React, { useEffect, useState } from 'react';
import { RepositoryFactory } from '@/apiRepository/RepositoryFactory';
import { getModuleFromUrlPath, utmToString } from '@/public/js/util';
import { useCookies } from 'react-cookie';
import { useSelector } from 'react-redux';
import { leadSubmitted } from '@/public/js/webengage';
import SuccessModal from './SuccessModal';
import { get } from 'lodash';

const AccountRepository = RepositoryFactory.get('account');
const ProUpgradeModal = () => {
  const dispatch = useDispatch();

  const [buttonText, setButtonText] = useState('Submit');
  const [validationErrorsName, setValidationErrorsName] = useState('');
  const [validationErrorsEmail, setValidationErrorsEmail] = useState('');
  const [validationErrorsCompany, setValidationErrorsCompany] = useState('');
  const [validationErrorsPhone, setValidationErrorsPhone] = useState('');
  // const [validationErrorsMessage, setValidationErrorsMessage] = useState('');
  const [countRemaining, setCountRemaining] = useState(128);
  const [cookies] = useCookies();
  const paid = useSelector((state: any) => state.sso.paid);
  const brandName = useSelector((state: any) => state.whitelist.brandName);
  const [formData, setFormData] = useState({
    name: '',
    phone: '',
    email: '',
    company: '',
    message: '',
    source: '',
  });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setFormData({
      ...formData,
      name: get(cookies, 'user.userAccount.name', ''),
      phone: get(cookies, 'user.userAccount.phone', ''),
      email: get(cookies, 'user.userAccount.email', ''),
      company: get(cookies, 'user.userAccount.brand.companyName', ''),
    });
  }, []);

  const regex =
    /^([\w-/.]+@(?!gmail)(?!yahoo.com)(?!hotmail.com)(?!yahoo.co.in)(?!aol.com)(?!abc.com)(?!xyz.com)(?!pqr.com)(?!rediffmail.com)(?!live.com)(?!outlook.com)(?!me.com)(?!msn.com)(?!ymail.com)(?!ymail.com)(?!protonmail.com)(?!icloud.com)([\w-]+\.)+[\w-]{2,4})?$/;
  const companyEmailCheck = (value: any) => regex.test(value);

  const regexName = /^[A-Za-z0-9 ]+$/;
  const nameCheck = (value: any) => regexName.test(value);
  const handleName = (e: any) => {
    setFormData({
      ...formData,
      name: e.target.value,
    });
    setValidationErrorsName('');
  };
  const handleEmail = (e: any) => {
    setFormData({
      ...formData,
      email: e.target.value,
    });
    setValidationErrorsEmail('');
  };
  const handleOrganization = (e: any) => {
    setFormData({
      ...formData,
      company: e.target.value,
    });
    setValidationErrorsCompany('');
  };
  const handlePhone = (e: any) => {
    setFormData({
      ...formData,
      phone: e.target.value,
    });
    setValidationErrorsPhone('');
  };
  const handleMessage = (e: any) => {
    setFormData({
      ...formData,
      message: e.target.value,
    });
    if (e.target.value && e.target.value.length) {
      setCountRemaining(128 - e.target.value.length);
    } else {
      setCountRemaining(128);
    }
    // setValidationErrorsMessage('');
  };
  const checkDataValidation = () => {
    let invalidFlag = false;
    if (!formData.name || (formData.name && formData.name.length < 4)) {
      setValidationErrorsName('This field is required');
      invalidFlag = true;
    }
    if (formData.name && !nameCheck(formData.name)) {
      setValidationErrorsName('Special characters are not allowed.');
      invalidFlag = true;
    }
    if (!formData.email) {
      setValidationErrorsEmail('This field is required');
      invalidFlag = true;
    }
    if (formData.email && !companyEmailCheck(formData.email)) {
      setValidationErrorsEmail('Please enter work email');
      invalidFlag = true;
    }
    if (!formData.phone || (formData.phone && formData.phone.length !== 10)) {
      setValidationErrorsPhone('Enter a valid phone number');
      invalidFlag = true;
    }
    if (
      !formData.company ||
      (formData.company && formData.company.length < 2)
    ) {
      setValidationErrorsCompany('This field is required');
      invalidFlag = true;
    }
    // if (!(formData.message && formData.message.length)) {
    //   setValidationErrorsMessage('This field is required');
    //   invalidFlag = true;
    // }
    return invalidFlag;
  };

  const createLead = async (payload: any) => {
    const name = payload.google_sheet.name.split(' ');
    try {
      const input = {
        pageIdentifier: 'SAAS',
        firstName: name[0] || '',
        lastName: name[1] || '',
        company: payload.google_sheet.company || '',
        requirement: payload.google_sheet.message + '(SaaS)',
        phone: payload.google_sheet.phone || '',
        email: payload.google_sheet.email || '',
        listKey:
          '3z8b2220a2e3699990526ad649f1cbd67150786ef06becf3821079c6960cb9f371',
        salesQualified: 'true',
        requirementViaSource: getModuleFromUrlPath() || '',
      };
      const { data } = await AccountRepository.createLead(input);
      console.log({ data });

      if (data && data.createLead && data.createLead.id) {
        window.localStorage.setItem('leadId', data.createLead.id);

        // dispatch(
        //   updateToastStatus({
        //     status: true,
        //     type: 'success',
        //     title: 'Success',
        //     desc: 'Successfully generated lead!',
        //   })
        // );
        const modalTrigger = document.getElementById('modalTrigger2');
        modalTrigger?.click();
        setButtonText('Submitted');
      } else {
        dispatch(
          updateToastStatus({
            status: true,
            type: 'danger',
            title: 'Error',
            desc: 'Something went wrong! Please try again.',
          })
        );
      }
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      if (error.code !== 'ERR_CANCELED') {
        dispatch(
          updateToastStatus({
            status: true,
            type: 'danger',
            title: 'Error',
            desc: 'Something went wrong! Please try again.',
          })
        );
      }
    }
  };
  const submit = () => {
    if (checkDataValidation()) {
      dispatch(
        updateToastStatus({
          status: true,
          type: 'warning',
          title: 'Warning',
          desc: 'Please fill all the required field!',
        })
      );
    } else if (buttonText !== 'Submitted') {
      setLoading(true);
      // wClickTracking('Lead form submitted', {
      //   'Full name': formData.name,
      //   'Phone number': formData.phone,
      //   'Work email': formData.email,
      //   'Company Name': formData.company,
      //   Description: formData.message,
      // });

      const tempData = JSON.parse(JSON.stringify(formData));
      const payload: any = {
        userId:
          cookies.user && cookies.user.userAccount
            ? cookies.user.userAccount.id
            : null,
        planType: paid ? 'Paid plan' : 'Free plan',
        brandId:
          cookies.user &&
          cookies.user.userAccount &&
          cookies.user.userAccount.brand
            ? cookies.user.userAccount.brand.id
            : null,
        requirement: tempData.message,
      };
      leadSubmitted(payload);
      let adSource = null;
      const utmParameters: any = window.sessionStorage.getItem('utmParameters');
      if (window.sessionStorage.getItem('adsSource')) {
        tempData.source +=
          '. Ads source - ' + window.sessionStorage.getItem('adsSource');

        adSource =
          ' Ads source - ' + window.sessionStorage.getItem('adsSource');
      } else if (utmParameters) {
        const utm = JSON.parse(utmParameters);
        tempData.source += ' ' + utmToString(utm);
        adSource = utmToString(utm);
      }

      const slackMessage =
        'Full Name: ' +
        tempData.name +
        ', Email: ' +
        tempData.email +
        ', Phone: ' +
        tempData.phone +
        ', Company: ' +
        tempData.company +
        ', Requirement: ' +
        tempData.offeringType +
        ', Message: ' +
        tempData.message +
        ', ' +
        tempData.source +
        ', Category: ' +
        tempData.category +
        ' (SaaS)';
      const pipedriveTitle =
        formData.source && formData.source.length && adSource
          ? formData.company +
            ' Campaign, Source - ' +
            formData.source +
            ', ' +
            adSource
          : formData.source && formData.source.length
          ? formData.company + ' Campaign, Source - ' + formData.source
          : formData.company;
      let pipedriveUserId = null;
      let pipedrivePipelineId = null;

      // if (formData.offeringType === 'INFLUENCER_COMMERCE') {
      //   pipedrivePipelineId = 6;
      //   pipedriveUserId = 12255546;
      // } else if (
      //   formData.offeringType === 'INFLUENCER_MARKETING' ||
      //   formData.offeringType === 'INTERNATIONAL_CAMPAIGNS'
      // ) {
      //   if (formData.budget === 'Less than 2 Lakhs') {
      //     pipedriveUserId = 12255546;
      //     pipedrivePipelineId = 5;
      //   } else {
      //     pipedriveUserId = 12255546; // Jhanvi
      //     pipedrivePipelineId = 1;
      //   }
      // }

      createLead({
        slack: {
          text: slackMessage,
        },
        pipedrive: {
          user_id: pipedriveUserId,
          pipeline_id: pipedrivePipelineId,
          title: pipedriveTitle,
          email: formData.email,
          name: formData.name,
          phone: formData.phone,
          company: formData.company,
        },
        google_sheet: {
          email: formData.email,
          name: formData.name,
          phone: formData.phone,
          company: formData.company,
          message: formData.message,
          source: tempData.source,
          sheet_name: 'Sheet1',
        },
        emails: {
          email: formData.email,
          name: formData.name,
          company: formData.company,
          ad_source: adSource,
        },
      });
      // fbLeadTracking();

      // if (
      //   utmParameters &&
      //   utmParameters.source &&
      //   utmParameters.source.toLowerCase() === 'googleads'
      // )
      //   gtagLeadConversion(null, formData.email);
    }
  };

  return (
    <>
      <div
        id="modalTrigger2"
        data-bs-toggle="modal"
        data-bs-target="#ThankYouPopup"
      ></div>
      <div
        id="ModalProUpgrade"
        role="dialog"
        aria-hidden="true"
        className={`modal fade ${style.modal_pro}`}
      >
        <div
          className="modal-dialog modal-dialog-centered"
          role="document"
          style={{ maxWidth: 674 }}
        >
          <div className="modal-content text-start">
            <i
              className={`bi bi-x ${style.close_modal}`}
              data-bs-dismiss="modal"
            ></i>
            <div className={`modal-body ${style.upgrade_modal}`}>
              <h2 className={style.modal_title}>
                Talk to our
                <br />
                <span>Certified experts</span>
              </h2>
              <p className={style.modal_subtitle}>
                Connect with Our Experienced Product Specialists to Learn How{' '}
                {brandName} Can Benefit Your Business!
              </p>

              <div className="row">
                <div className="col-sm-6">
                  <div className={style.field_dv}>
                    <input
                      className={style.inpt_txt}
                      type="text"
                      placeholder="Name*"
                      onChange={handleName}
                      value={formData.name}
                    />
                    {validationErrorsName && validationErrorsName.length ? (
                      <span className={style.error}>
                        {validationErrorsName}
                      </span>
                    ) : null}
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className={style.field_dv}>
                    <input
                      className={style.inpt_txt}
                      type="email"
                      placeholder="Email*"
                      onChange={handleEmail}
                      value={formData.email}
                    />
                    {validationErrorsEmail && validationErrorsEmail.length ? (
                      <span className={style.error}>
                        {validationErrorsEmail}
                      </span>
                    ) : null}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-6">
                  <div className={style.field_dv}>
                    <input
                      className={style.inpt_txt}
                      type="text"
                      placeholder="Organization*"
                      onChange={handleOrganization}
                      value={formData.company}
                    />
                    {validationErrorsCompany &&
                    validationErrorsCompany.length ? (
                      <span className={style.error}>
                        {validationErrorsCompany}
                      </span>
                    ) : null}
                  </div>
                </div>
                <div className="col-sm-6">
                  <div
                    className={`${style.field_dv} d-flex align-items-center`}
                  >
                    <span
                      style={{
                        fontSize: '14px',
                        borderBottom: '1px solid #eaeaea',
                        padding: '10px 5px',
                        color: '#a6a6a6',
                      }}
                    >
                      +91
                    </span>
                    <input
                      className={style.inpt_txt}
                      type="number"
                      placeholder="Phone*"
                      onChange={handlePhone}
                      value={formData.phone}
                    />
                    {validationErrorsPhone && validationErrorsPhone.length ? (
                      <span className={style.error}>
                        {validationErrorsPhone}
                      </span>
                    ) : null}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-12">
                  <div className={style.txtarea_dv}>
                    <textarea
                      className={style.txtarea}
                      placeholder="Tell us how we can help you?"
                      onChange={handleMessage}
                      maxLength={128}
                    ></textarea>
                    <span className={style.word_remaining}>
                      {countRemaining} words remaining{' '}
                    </span>

                    {/* {validationErrorsMessage &&
                    validationErrorsMessage.length ? (
                      <span className={style.error_txtarea}>
                        {validationErrorsMessage}
                      </span>
                    ) : null} */}
                  </div>
                  <div className={style.experts_container}>
                    <span>
                      <img
                        src="/icons/shoutout.svg"
                        width="20px"
                        height="20px"
                        style={{ marginRight: 5 }}
                      />
                    </span>{' '}
                    <span>
                      Need help with your influencer marketing campaigns?{' '}
                      <a
                        href="https://goodcreator.co/talk-to-us"
                        target="_blank"
                      >
                        Connect with our experts
                      </a>
                    </span>
                  </div>
                </div>
              </div>
              <button
                className={`btn btn-sm ${style.btn_black}`}
                onClick={submit}
                disabled={buttonText === 'Submitted' || loading}
              >
                {loading ? 'Loading...' : buttonText}
              </button>
            </div>
          </div>
        </div>
      </div>
      <SuccessModal />
    </>
  );
};
export default ProUpgradeModal;
