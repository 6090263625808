import { createSlice } from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';

const initialState = {
  isWhitelist: false,
  accessRoutes: [
    '/account',
    '/report/campaign/[id]',
    '/report/share/[id]',
    '/offline-campaign-reports',
    '/smart-campaigns/create-campaign?requirement=INFLUENCER_MARKETING',
    '/smart-campaigns/create-campaign?requirement=CONTENT_GENERATION',
  ],
  defaultRoute: '/offline-campaign-reports',
  whitelistBrandLogo: null,
  brandName: 'The Good Creator Co.',
};

export const whitelistSlice = createSlice({
  name: 'whitelist',
  initialState,
  reducers: {
    setIsWhitelist(state, action) {
      state.isWhitelist = action.payload;

      if (action.payload) state.brandName = 'Momentum';
    },
    setWhitelistBrandLogo(state, action) {
      state.whitelistBrandLogo = action.payload;
    },
    // Special reducer for hydrating the state
    extraReducers: {
      [HYDRATE]: (state, action) => {
        return {
          ...state,
          ...action.payload.whitelist,
        };
      },
    },
  },
});

export const { setIsWhitelist, setWhitelistBrandLogo } = whitelistSlice.actions;

export default whitelistSlice.reducer;
