import { useDispatch } from 'react-redux';
import styles from '../../styles/OnboardingModal.module.css';
import { updateToastStatus } from '@/store/general';
import { useState, useEffect } from 'react';
import { useCookies } from 'react-cookie';
const _ = require('lodash');

const list = [
  'Drive measurable results on my influencer marketing campaigns',
  'Amplify my content with the help of influencers',
  'Get reviews created for your products',
  'Manage your influencer marketing strategy & execution',
  'Discover influencers through a search with filters',
  'Explore your competitors, their strategies, and find inspiring advertising insights',
  'Generate report for an existing campaign',
  "Analyse the influencer profiles you've already found",
  "Other ('Describe your requirement')",
];

const QuestionsPage5 = ({ setPageNo, data, setData, handleSubmit }: any) => {
  const dispatch = useDispatch();
  const [options, setOptions] = useState(list);
  const [cookies] = useCookies(['user']);

  useEffect(() => {
    let tempData = list.slice();
    if (_.get(cookies, 'user.userAccount.email', '').includes('@gmail')) {
      tempData.splice(0, 0, 'Not sure, just exploring');
      setOptions(tempData);
    } else {
      tempData.splice(8, 0, 'Not sure, just exploring');
      setOptions(tempData);
    }
  }, []);

  return (
    <div>
      <div className={styles.text_container}>
        <div className={styles.title}>How can The Good Creator Co. help?</div>
        <div className={styles.options}>
          {options?.map((option: string, idx: number) => {
            return (
              <div className={styles.option} key={`option${idx}`}>
                <input
                  type="radio"
                  style={{
                    width: 24,
                    height: 24,
                    cursor: 'pointer',
                  }}
                  checked={option === data.help.value}
                  onChange={() =>
                    setData({
                      ...data,
                      help: {
                        ...data.help,
                        value: option,
                      },
                    })
                  }
                />
                <div className={styles.collective_option}>
                  <div className={styles.option_name}>{option}</div>
                  {data.help.value === "Other ('Describe your requirement')" &&
                    option === "Other ('Describe your requirement')" && (
                      <input
                        className={styles.other_textarea}
                        type="text"
                        placeholder="Describe your requirement"
                        value={data.help.otherInput}
                        onChange={(e: any) =>
                          setData({
                            ...data,
                            help: {
                              ...data.help,
                              otherInput: e.target.value,
                            },
                          })
                        }
                      />
                    )}
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div className={styles.btns}>
        <div className={styles.back_btn} onClick={() => setPageNo(4)}>
          Back
        </div>
        <button
          className={
            !data.help.value ||
            (data.help.value === "Other ('Describe your requirement')" &&
              data.help.otherInput.length === 0)
              ? styles.get_started_btn_disabled
              : styles.get_started_btn
          }
          onClick={() => {
            if (!data.help.value) {
              dispatch(
                updateToastStatus({
                  status: true,
                  type: 'danger',
                  title: 'Error',
                  desc: 'Please choose an option!',
                })
              );
              return;
            } else if (
              data.help.value === "Other ('Describe your requirement')" &&
              data.help.otherInput.length === 0
            ) {
              dispatch(
                updateToastStatus({
                  status: true,
                  type: 'danger',
                  title: 'Error',
                  desc: 'Please enter the Other field!',
                })
              );
              return;
            }

            handleSubmit();
          }}
        >
          Get started
        </button>
      </div>
    </div>
  );
};

export default QuestionsPage5;
