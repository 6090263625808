import Link from 'next/link';
import React, { useRef, useMemo, useEffect } from 'react';
import style from '@/styles/Header.module.css';
import HeaderSearchSuggestion from '@/components/molecules/HeaderSearchSuggestion';
import { useState } from 'react';
import { useRouter } from 'next/router';
import { useOutsideAlerter } from '@/public/js/util';
import { useDispatch } from 'react-redux';
import { RepositoryFactory } from '@/apiRepository/RepositoryFactory';
const DiscoveryRepository = RepositoryFactory.get('discovery');
import { updateToastStatus } from '@/store/general';
import { useSelector } from 'react-redux';
import {
  setLoadingPaidRestrictions,
  setModalType,
} from '@/modules/sso/store/sso';
import { useCookies } from 'react-cookie';
import DefaultImage from '@/components/atoms/DefaultImage';
import ProUpgrade from '@/components/molecules/ProUpgrade';
import { topNavSearchUsed, userLogOut } from '@/public/js/webengage';
import ProUpgradeModal from '@/components/molecules/ProUpgradeModal';
const AuthRepository = RepositoryFactory.get('auth');
import { get } from 'lodash';
import { getPaidRestrictionsThunk } from '@/modules/sso/store/middlewares';

const _ = require('lodash');

const WhitelistHeader = () => {
  const router = useRouter();
  const dispatch = useDispatch<any>();
  const [cookies, , removeCookies] = useCookies();
  const brandId = window.sessionStorage.getItem('brandId');
  const loggedIn = useSelector((state: any) => state.sso.loggedIn);
  const whitelistBrandLogo = useSelector(
    (state: any) => state.whitelist.whitelistBrandLogo
  );
  const [pinMenu, setPinMenu] = useState(false);
  const [keyword, setKeyword] = useState('');
  const [suggestionModal, setSuggestionModal] = useState({
    loading: false,
    data: [],
    show: false,
    searchPhrase: '',
  });
  const paid = useSelector((state: any) => state.sso.paid);

  useEffect(() => {
    let paidRestrInterval: any = null;
    if (paid) {
      const brandId = get(cookies, 'user.userAccount.brand.id', null);
      dispatch(getPaidRestrictionsThunk({ brandId }));
      paidRestrInterval = setInterval(() => {
        dispatch(getPaidRestrictionsThunk({ brandId }));
      }, 5 * 60 * 1000);
    } else {
      dispatch(setLoadingPaidRestrictions(false));
    }
    return () => {
      if (paid) clearInterval(paidRestrInterval);
    };
  }, []);

  const outsideClickHandler = () => {
    setSuggestionModal({
      ...suggestionModal,
      show: false,
    });
  };

  const authHandler = (type: string) => {
    const redirectUrl = window.location.href;
    window.location.href = `${window.location.origin}/sso/${type}?redirectUrl=${redirectUrl}`;
  };

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef, outsideClickHandler);
  const userAgent = navigator.userAgent;
  const isSafari =
    userAgent.indexOf('Chrome') > -1
      ? false
      : userAgent.indexOf('Safari') > -1
      ? true
      : false;

  const navList = [
    {
      title: 'Home',
      iconUrl: '/images/home-icon.svg',
      url: '/',
      activeKey: '/',
    },
    {
      title: 'Creator Discovery',
      iconUrl: '/images/creator_discovery-icon.svg',
      url: '/discovery',
      activeKey: '/discovery',
    },
    {
      title: 'Leaderboard',
      iconUrl: '/images/leaderboard-icon.svg',
      url: '/leaderboard',
      activeKey: '/leaderboard',
    },
    {
      title: 'Collection',
      iconUrl: '/images/collections-icon.svg',
      url: '/collections',
      activeKey: '/collection',
    },
    // {
    //   title: 'My Activity',
    //   iconUrl: '/images/activity-icon.svg',
    //   url: '/activity',
    // },
    {
      title: 'Campaign Report',
      iconUrl: '/images/report.svg',
      url: '/offline-campaign-reports',
      activeKey: '/offline-campaign-reports',
    },
    {
      title: 'Genre Insight',
      iconUrl: '/images/genre_insight.svg',
      url: '/genre',
      activeKey: '/genre',
    },
    {
      title: 'Account Tracking',
      iconUrl: '/images/account-tracking.svg',
      url: '/account-tracking',
      activeKey: '/account-tracking',
    },
  ];

  const getSearchResut = async (inputValue: string) => {
    setSuggestionModal(() => ({
      ...suggestionModal,
      show: true,
      loading: true,
      data: [],
      searchPhrase: inputValue,
    }));
    const payload: any = {
      userId:
        cookies.user && cookies.user.userAccount
          ? cookies.user.userAccount.id
          : null,
      planType: paid ? 'Paid plan' : 'Free plan',
      brandId:
        cookies.user &&
        cookies.user.userAccount &&
        cookies.user.userAccount.brand
          ? cookies.user.userAccount.brand.id
          : null,
      searchTerm: keyword,
    };
    topNavSearchUsed(payload);
    try {
      const { data } = await DiscoveryRepository.getInfluencers(
        {
          filters: [
            {
              field: 'search_phrase',
              filterType: 'LIKE',
              value: inputValue,
            },
          ],
        },
        {
          cursor: 1,
          size: 5,
          sortBy: 'followers',
          sortDir: 'DESC',
          platform: 'ALL',
        }
      );

      if (
        data &&
        data.status &&
        data.status.status === 'SUCCESS' &&
        data.profiles
      ) {
        setSuggestionModal(() => ({
          ...suggestionModal,
          show: true,
          loading: false,
          data: data.profiles.slice(0, 5),
          searchPhrase: inputValue,
        }));
      } else {
        setSuggestionModal({
          ...suggestionModal,
          show: true,
          loading: false,
          data: [],
        });
      }
    } catch (error: any) {
      setSuggestionModal({
        ...suggestionModal,
        show: true,
        loading: false,
        data: [],
      });

      console.log('error');
      console.error(error);
    }
  };

  const debounceFn = useMemo(() => _.debounce(handleDebounceFn, 500), []);

  function handleDebounceFn(inputValue: string) {
    if (inputValue && inputValue.length > 0) {
      getSearchResut(inputValue);
    } else
      setSuggestionModal({
        ...suggestionModal,
        show: false,
      });
  }

  const keywordHandler = (value: string) => {
    setKeyword(value);
    debounceFn(value);
  };

  const inputFocusHandler = () => {
    if (keyword && keyword.length > 0) {
      getSearchResut(keyword);
    }
  };

  const clearCookies = async () => {
    try {
      // const { data } =
      await AuthRepository.logoutV2();
      const payload: any = {
        userId:
          cookies.user && cookies.user.userAccount
            ? cookies.user.userAccount.id
            : null,
        planType: paid ? 'Paid plan' : 'Free plan',
        brandId:
          cookies.user &&
          cookies.user.userAccount &&
          cookies.user.userAccount.brand
            ? cookies.user.userAccount.brand.id
            : null,
      };
      userLogOut(payload);
      let SUBDOMAIN: any = 'goodcreator.co';
      if (window.location.hostname.match('vidooly')) SUBDOMAIN = 'vidooly.com';
      if (window.location.hostname.match('localhost')) SUBDOMAIN = 'localhost';
      removeCookies('user', { path: '/', domain: SUBDOMAIN });
      window.localStorage.removeItem('permissions');
      window.sessionStorage.removeItem('anonymousToken');
      window.location.reload();
    } catch (error: any) {
      if (error.code !== 'ERR_CANCELED') {
        dispatch(
          updateToastStatus({
            status: true,
            type: 'danger',
            title: 'Error',
            desc: 'Something went wrong! Please try again.',
          })
        );
      }
    }
  };

  return (
    <>
      <nav
        className={`navbar navbar-expand navbar-light fixed-top ${style.bg_header}`}
      >
        <a className="navbar-brand" href="/" style={{ marginLeft: '50px' }}>
          <img src={whitelistBrandLogo} alt="" height={35} />
        </a>

        <ul className={`navbar-nav ms-auto ${style.nav_header}`}>
          <li className={`nav-item ${style.only_desktop}`}></li>
          {loggedIn ? (
            <li className="nav-item dropdown">
              <Link
                className={`nav-link dropdown-toggle ${style.dropdown_toggle}`}
                href="#"
                id="navbarDropdownMenuLink"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <div
                  className={style.inf_pic_cont}
                  style={{ width: '34px', height: '34px' }}
                >
                  <DefaultImage
                    width="34"
                    height="34"
                    name={
                      cookies.user && cookies.user.name
                        ? cookies.user.name
                        : 'A'
                    }
                    index={1}
                    fontSize="20px"
                    thumbnail={null}
                    borderRadius="50%"
                    className={style.img_bg}
                  />
                </div>
              </Link>
              <ul
                className={`dropdown-menu ${style.right}`}
                aria-labelledby="navbarDropdownMenuLink"
              >
                <li>
                  <Link
                    href="/account"
                    className={`dropdown-item ${style.dropdown_text}`}
                  >
                    My Account
                  </Link>
                </li>
                <li>
                  <Link
                    href="#"
                    onClick={clearCookies}
                    className={`dropdown-item ${style.dropdown_text}`}
                  >
                    Logout
                  </Link>
                </li>
              </ul>
            </li>
          ) : (
            <li className={`nav-item ${style.only_desktop} ${style.row}`}>
              <div
                className={`${style.btn_no_border}`}
                onClick={() => authHandler('login')}
              >
                Login
              </div>
              <button
                className={`btn ${style.btn_black}`}
                onClick={() => authHandler('signup')}
              >
                Sign up
              </button>
            </li>
          )}
        </ul>
      </nav>

      <ProUpgradeModal />
    </>
  );
};
export default WhitelistHeader;
