import { useDispatch } from 'react-redux';
import styles from '../../styles/OnboardingModal.module.css';
import { updateToastStatus } from '@/store/general';

const options = [
  'Founder',
  'Chief Marketing Officer',
  'Influencer Marketing Manager',
  'Social media marketer with an influencer marketing responsibility',
  'Digital marketing specialist with an influencer marketing responsibility',
  'I do all of the marketing for my company',
  'I do sales for my organisation',
  "I don't deal with marketing in my company day to day",
  'Project/Product manager, making influencer marketing proposals',
  'Other',
];

const QuestionsPage2 = ({ setPageNo, data, setData, webengageEvent }: any) => {
  const dispatch = useDispatch();

  return (
    <div>
      <div className={styles.text_container}>
        <div className={styles.title}>What is your role in the company?</div>
        <div className={styles.options}>
          {options?.map((option: string, idx: number) => {
            return (
              <div className={styles.option} key={`option${idx}`}>
                <input
                  type="radio"
                  style={{
                    width: 24,
                    height: 24,
                    cursor: 'pointer',
                  }}
                  checked={option === data.roleInCompany.value}
                  onChange={() =>
                    setData({
                      ...data,
                      roleInCompany: {
                        ...data.roleInCompany,
                        value: option,
                      },
                    })
                  }
                />
                <div className={styles.collective_option}>
                  <div className={styles.option_name}>{option}</div>
                  {data.roleInCompany.value === 'Other' &&
                    option === 'Other' && (
                      <input
                        className={styles.other_textarea}
                        type="text"
                        placeholder="Please specify your role."
                        onChange={(e: any) =>
                          setData({
                            ...data,
                            roleInCompany: {
                              ...data.roleInCompany,
                              otherInput: e.target.value,
                            },
                          })
                        }
                        value={data.roleInCompany.otherInput}
                      />
                    )}
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div className={styles.btns}>
        <div className={styles.back_btn} onClick={() => setPageNo(1)}>
          Back
        </div>
        <div
          className={
            !data.roleInCompany.value ||
            (data.roleInCompany.value === 'Other' &&
              data.roleInCompany.otherInput.length === 0)
              ? styles.next_btn_disabled
              : styles.next_btn
          }
          onClick={() => {
            if (!data.roleInCompany.value) {
              dispatch(
                updateToastStatus({
                  status: true,
                  type: 'danger',
                  title: 'Error',
                  desc: 'Please choose an option!',
                })
              );
              return;
            } else if (
              data.roleInCompany.value === 'Other' &&
              data.roleInCompany.otherInput.length === 0
            ) {
              dispatch(
                updateToastStatus({
                  status: true,
                  type: 'danger',
                  title: 'Error',
                  desc: 'Please enter the Other field!',
                })
              );
              return;
            }

            setPageNo(3);
            webengageEvent({
              eventName: 'VS - User Role Selected',
              value: {
                'User Role':
                  data.roleInCompany.value === 'Other'
                    ? data.roleInCompany.otherInput
                    : data.roleInCompany.value,
              },
            });
          }}
        >
          Next
        </div>
      </div>
    </div>
  );
};

export default QuestionsPage2;
