import ToastMessage from '@/components/atoms/Toast';
import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setIsWhitelist, setWhitelistBrandLogo } from '@/store/whitelist';

const EmptyLayout = ({ children }: any) => {
  const dispatch = useDispatch();

  useEffect(() => {
    const isWhitelist =
      process.env.NEXT_PUBLIC_DOMAIN_ENV === 'whitelist' ||
      window.location.hostname.includes('momentum.goodcreator.co');

    dispatch(setIsWhitelist(isWhitelist));

    if (isWhitelist)
      dispatch(
        setWhitelistBrandLogo(
          'https://d22h6s1qvdt49m.cloudfront.net/tr:w-600/static/64805c247318f95df6f13629.png'
        )
      );
    else
      dispatch(
        setWhitelistBrandLogo(
          'https://d22h6s1qvdt49m.cloudfront.net/tr:w-600/static/6488b8ee7318f95df6f16d75.png'
        )
      );
  }, []);

  return (
    <div>
      {children}
      <ToastMessage />
    </div>
  );
};

export default EmptyLayout;
