import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  pendingCollections: [],
  allBECollectionsCount: 0,
};

export const accountTracking = createSlice({
  name: 'accountTracking',
  initialState,
  reducers: {
    addPendingCollections(state, action) {
      state.pendingCollections = [action.payload, ...state.pendingCollections];
    },
    allBECollectionsCount(state, action) {
      state.allBECollectionsCount = action.payload;
    },
  },
});

export const { addPendingCollections, allBECollectionsCount } =
  accountTracking.actions;
export default accountTracking.reducer;
