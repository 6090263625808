import { createSlice } from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';

const initialState = {
  pageData: {
    general: {},
  },
  searchKey: '',
};

export const pageDataSlice = createSlice({
  name: 'pageData',
  initialState,
  reducers: {
    // Action to add
    createPageData: (state, action) => {
      state.pageData = {
        ...state.pageData,
        [action.payload.key]: action.payload.value,
      };
      console.log('a');
    },
    updatePageData: (state, action) => {
      console.log('b');
      console.log(action.payload.value);
      console.log(state);
      state.pageData = {
        ...state.pageData,
        [action.payload.key]: {
          ...state.pageData[action.payload.key],
          ...action.payload.value,
        },
      };
    },
    setSearchKey: (state, action) => {
      state.searchKey = action.payload;
    },
    resetPageData: (state) => {
      state.pageData = { general: {} };
    },

    // Special reducer for hydrating the state
    extraReducers: {
      [HYDRATE]: (state, action) => {
        return {
          ...state,
          ...action.payload.filters,
        };
      },
    },
  },
});

export const { createPageData, updatePageData, resetPageData, setSearchKey } =
  pageDataSlice.actions;

export default pageDataSlice.reducer;
