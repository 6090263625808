import Link from 'next/link';
import Image from 'next/image';
import React, { useRef, useMemo, useEffect } from 'react';
import style from '@/styles/Header.module.css';
import HeaderSearchSuggestion from '../molecules/HeaderSearchSuggestion';
import { useState } from 'react';
import { useRouter } from 'next/router';
import { useOutsideAlerter } from '@/public/js/util';
import { useDispatch } from 'react-redux';
import { RepositoryFactory } from '@/apiRepository/RepositoryFactory';
const DiscoveryRepository = RepositoryFactory.get('discovery');
import { updateToastStatus } from '@/store/general';
import { useSelector } from 'react-redux';
import {
  setModalType,
  setLoadingPaidRestrictions,
} from '@/modules/sso/store/sso';
import { useCookies } from 'react-cookie';
import DefaultImage from '../atoms/DefaultImage';
import ProUpgrade from '../molecules/ProUpgrade';
import { topNavSearchUsed, userLogOut } from '@/public/js/webengage';
import ProUpgradeModal from '../molecules/ProUpgradeModal';
import CouponModalTemplate from '@/modules/reward-ladder/components/templates/CouponModalTemplate';
import { getPaidRestrictionsThunk } from '@/modules/sso/store/middlewares';
const AuthRepository = RepositoryFactory.get('auth');
import { get } from 'lodash';

const _ = require('lodash');

const Header = () => {
  const router = useRouter();
  const dispatch = useDispatch<any>();
  const [cookies, , removeCookies] = useCookies();
  const brandId = window.sessionStorage.getItem('brandId');
  const loggedIn = useSelector((state: any) => state.sso.loggedIn);
  const [pinMenu, setPinMenu] = useState(true);
  const [keyword, setKeyword] = useState('');
  const [suggestionModal, setSuggestionModal] = useState({
    loading: false,
    data: [],
    show: false,
    searchPhrase: '',
  });
  const paid = useSelector((state: any) => state.sso.paid);

  useEffect(() => {
    let paidRestrInterval: any = null;
    if (paid) {
      const brandId = get(cookies, 'user.userAccount.brand.id', null);
      dispatch(getPaidRestrictionsThunk({ brandId }));
      paidRestrInterval = setInterval(() => {
        dispatch(getPaidRestrictionsThunk({ brandId }));
      }, 5 * 60 * 1000);
    } else {
      dispatch(setLoadingPaidRestrictions(false));
    }
    if (loggedIn) {
      if (window.localStorage.getItem('pinMenu') === 'false') {
        setPinMenu(false);
      }
    }
    const mobileThreshold = 640;
    const checkMobile = () => {
      if (window.innerWidth <= mobileThreshold) setPinMenu(false);
      if (
        window.location.href.includes('collection/share') ||
        window.location.href.includes('influencer/share')
      )
        setPinMenu(false);
    };
    checkMobile();
    window.addEventListener('resize', checkMobile);
    return () => {
      if (paid) clearInterval(paidRestrInterval);
      window.removeEventListener('resize', checkMobile);
    };
  }, []);

  const outsideClickHandler = () => {
    setSuggestionModal({
      ...suggestionModal,
      show: false,
    });
  };

  const authHandler = (type: string) => {
    const redirectUrl = window.location.href;
    window.location.href = `${window.location.origin}/sso/${type}?redirectUrl=${redirectUrl}`;
  };

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef, outsideClickHandler);
  const userAgent = navigator.userAgent;
  const isSafari =
    userAgent.indexOf('Chrome') > -1
      ? false
      : userAgent.indexOf('Safari') > -1
      ? true
      : false;

  const navList = [
    {
      title: 'Home',
      iconUrl: '/images/home-icon.svg',
      url: '/',
      activeKey: '/',
      category: 'main',
    },
    {
      title: 'Creator Discovery',
      iconUrl: '/images/creator_discovery-icon.svg',
      url: '/discovery',
      activeKey: '/discovery',
      category: 'main',
    },
    {
      title: 'Collections',
      iconUrl: '/images/collections-icon.svg',
      url: '/collections',
      activeKey: '/collection',
      category: 'main',
    },
    // {
    //   title: 'Leaderboard',
    //   iconUrl: '/images/leaderboard-icon.svg',
    //   url: '/leaderboard',
    //   activeKey: '/leaderboard',
    //   category: 'analytical tools',
    // },
    // {
    //   title: 'Topic Research',
    //   iconUrl: '/images/content-discovery.svg',
    //   url: '/topic-research',
    //   activeKey: '/topic-research',
    //   category: 'analytical tools',
    // },
    {
      title: 'Account Tracking',
      iconUrl: '/images/account-tracking.svg',
      url: '/account-tracking',
      activeKey: '/account-tracking',
      category: 'analytical tools',
    },
    // {
    //   title: 'Genre Insight',
    //   iconUrl: '/images/genre_insight.svg',
    //   url: '/genre',
    //   activeKey: '/genre',
    //   category: 'analytical tools',
    // },
    {
      title: 'Smart Campaigns',
      iconUrl: '/images/reward-ladder.svg',
      url: '/smart-campaigns',
      activeKey: '/smart-campaigns',
      category: 'campaign tools',
    },
    {
      title: 'Campaign Report',
      iconUrl: '/images/report.svg',
      url: '/offline-campaign-reports',
      activeKey: '/offline-campaign-reports',
      category: 'campaign tools',
    },
    // {
    //   title: 'My Activity',
    //   iconUrl: '/images/activity-icon.svg',
    //   url: '/activity',
    // },
  ];

  const getSearchResut = async (inputValue: string) => {
    setSuggestionModal(() => ({
      ...suggestionModal,
      show: true,
      loading: true,
      data: [],
      searchPhrase: inputValue,
    }));
    const payload: any = {
      userId:
        cookies.user && cookies.user.userAccount
          ? cookies.user.userAccount.id
          : null,
      planType: paid ? 'Paid plan' : 'Free plan',
      brandId:
        cookies.user &&
        cookies.user.userAccount &&
        cookies.user.userAccount.brand
          ? cookies.user.userAccount.brand.id
          : null,
      searchTerm: keyword,
    };
    topNavSearchUsed(payload);
    try {
      const { data } = await DiscoveryRepository.getInfluencers(
        {
          filters: [
            {
              field: 'search_phrase',
              filterType: 'LIKE',
              value: inputValue,
            },
          ],
        },
        {
          cursor: 1,
          size: 5,
          sortBy: 'followers',
          sortDir: 'DESC',
          platform: 'ALL',
        }
      );

      if (
        data &&
        data.status &&
        data.status.status === 'SUCCESS' &&
        data.profiles
      ) {
        setSuggestionModal(() => ({
          ...suggestionModal,
          show: true,
          loading: false,
          data: data.profiles.slice(0, 5),
          searchPhrase: inputValue,
        }));
      } else {
        setSuggestionModal({
          ...suggestionModal,
          show: true,
          loading: false,
          data: [],
        });
      }
    } catch (error: any) {
      setSuggestionModal({
        ...suggestionModal,
        show: true,
        loading: false,
        data: [],
      });
      // if (error.code !== 'ERR_CANCELED') {
      //   dispatch(
      //     updateToastStatus({
      //       status: true,
      //       type: 'danger',
      //       title: 'Error',
      //       desc: 'Something went wrong! Please try again.',
      //     })
      //   );
      // }

      console.log('error');
      console.error(error);
    }
  };

  const debounceFn = useMemo(() => _.debounce(handleDebounceFn, 500), []);

  function handleDebounceFn(inputValue: string) {
    if (inputValue && inputValue.length > 0) {
      getSearchResut(inputValue);
    } else
      setSuggestionModal({
        ...suggestionModal,
        show: false,
      });
  }

  const keywordHandler = (value: string) => {
    setKeyword(value);
    debounceFn(value);
  };

  const inputFocusHandler = () => {
    if (keyword && keyword.length > 0) {
      getSearchResut(keyword);
    }
  };
  const clearCookies = async () => {
    try {
      // const { data } =
      await AuthRepository.logoutV2();
      const payload: any = {
        userId:
          cookies.user && cookies.user.userAccount
            ? cookies.user.userAccount.id
            : null,
        planType: paid ? 'Paid plan' : 'Free plan',
        brandId:
          cookies.user &&
          cookies.user.userAccount &&
          cookies.user.userAccount.brand
            ? cookies.user.userAccount.brand.id
            : null,
      };
      userLogOut(payload);
      let SUBDOMAIN: any = 'goodcreator.co';
      if (window.location.hostname.match('vidooly')) SUBDOMAIN = 'vidooly.com';
      if (window.location.hostname.match('localhost')) SUBDOMAIN = 'localhost';
      removeCookies('user', { path: '/', domain: SUBDOMAIN });
      window.localStorage.removeItem('permissions');
      // console.log({ data });
      // const today = new Date();
      // let after30D = new Date();
      // after30D.setDate(today.getDate() + 30);
      // setCookie(
      //   'user',
      //   {
      //     userToken: data.logoutV2.token,
      //     refreshToken: data.logoutV2.refreshToken,
      //   },
      //   {
      //     domain: SUBDOMAIN,
      //     expires: after30D,
      //     path: '/',
      //   }
      // );
      window.sessionStorage.removeItem('anonymousToken');
      window.location.reload();
    } catch (error: any) {
      if (error.code !== 'ERR_CANCELED') {
        dispatch(
          updateToastStatus({
            status: true,
            type: 'danger',
            title: 'Error',
            desc: 'Something went wrong! Please try again.',
          })
        );
      }
    }
  };

  const mainNavList = navList?.filter((node) => node.category === 'main');
  const analyticalToolsNavList = navList?.filter(
    (node) => node.category === 'analytical tools'
  );
  const campaignToolsNavList = navList?.filter(
    (node) => node.category === 'campaign tools'
  );

  return (
    <>
      <nav
        className={`navbar navbar-expand navbar-light fixed-top ${style.bg_header}`}
      >
        <ul className={`navbar-nav ms-auto ${style.nav_header}`}>
          <li className={`nav-item ${style.only_desktop}`}>
            <div className={style.sugg_block} ref={wrapperRef}>
              <div className={style.input_icon}>
                <i className="bi bi-search"></i>
                <input
                  className={style.search_input}
                  type="text"
                  value={keyword || ''}
                  onChange={(e) => keywordHandler(e.target.value)}
                  onFocus={() => inputFocusHandler()}
                  placeholder="Analyse any Influencer..."
                />
              </div>
              {suggestionModal.show && (
                <HeaderSearchSuggestion suggestionModal={suggestionModal} />
              )}
            </div>
          </li>
          {/* <li className={`nav-item ${style.only_desktop}`}>
            <Link className="nav-link" href="#">
              <i className={`bi bi-question-circle ${style.header_icon}`}></i>
            </Link>
          </li>
          <li className={`nav-item ${style.only_desktop}`}>
            <Link className="nav-link" href="#">
              <i className={`bi bi-gear ${style.header_icon}`}></i>
            </Link>
          </li> */}
          {loggedIn ? (
            <li className="nav-item dropdown">
              <Link
                className={`nav-link dropdown-toggle ${style.dropdown_toggle}`}
                href="#"
                id="navbarDropdownMenuLink"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <div
                  className={style.inf_pic_cont}
                  style={{ width: '34px', height: '34px' }}
                >
                  <DefaultImage
                    width="34"
                    height="34"
                    name={
                      cookies.user && cookies.user.name
                        ? cookies.user.name
                        : 'A'
                    }
                    index={1}
                    fontSize="20px"
                    thumbnail={null}
                    borderRadius="50%"
                    className={style.img_bg}
                  />
                </div>
              </Link>
              <ul
                className={`dropdown-menu ${style.right}`}
                aria-labelledby="navbarDropdownMenuLink"
              >
                <li>
                  <Link
                    href="/account"
                    className={`dropdown-item ${style.dropdown_text}`}
                  >
                    My Account
                  </Link>
                </li>
                <li>
                  <Link
                    href="#"
                    onClick={clearCookies}
                    className={`dropdown-item ${style.dropdown_text}`}
                  >
                    Logout
                  </Link>
                </li>
              </ul>
            </li>
          ) : (
            <li className={`nav-item ${style.only_desktop} ${style.row}`}>
              <div
                className={`${style.btn_no_border}`}
                onClick={() => authHandler('login')}
              >
                Login
              </div>
              <button
                className={`btn ${style.btn_black}`}
                onClick={() => authHandler('signup')}
              >
                Sign up
              </button>
            </li>
          )}
        </ul>
      </nav>

      {/* **************************Sidebar Code Start************************************* */}

      <nav className={`${style.sidebar} ${pinMenu ? style.close : ''}`}>
        <div className={style.logo_div}>
          <a href="/">
            <div className={style.logo}>
              <img
                src="https://firebasestorage.googleapis.com/v0/b/winkl-1095.appspot.com/o/gcc-website%2Fimages%2Flogo-Gccx.png?alt=media&token=d847a36e-fcfa-48bd-83a1-a10916ad11dd"
                className={style.logo_icon}
                alt="saas"
                width={176}
              />
              <img
                src="https://d22h6s1qvdt49m.cloudfront.net/tr:w-600/static/6453dd2c7318f945ee80ac6b.png"
                className={style.full_logo}
                alt="saas"
                width={800}
              />
            </div>
          </a>

          <div
            className={style.arrow}
            onClick={() => {
              setPinMenu(!pinMenu);
              console.log({ ssss: !pinMenu });
              window.localStorage.setItem('pinMenu', `${!pinMenu}`);
            }}
          >
            <div className={`${style.slide_icon} ${style.only_desktop}`}>
              <i className={`bi bi-pin-angle-fill ${style.pin}`}></i>
              <i className={`bi bi-chevron-left ${style.slide_left}`}></i>
            </div>
            <div className={style.only_mobile}>
              <span className={style.bar}>
                <i className="bi bi-list"></i>
              </span>
            </div>
          </div>
        </div>
        <div className={style.menu_bar}>
          <ul>
            {mainNavList?.map((node, index) => (
              <li
                className={style.nav_link}
                key={`headerNavItem-${index}`}
                style={{ height: '54px' }}
              >
                {!loggedIn && node.url == '/collections' ? (
                  <Link
                    href={''}
                    className={
                      (router.pathname.includes(node.activeKey) &&
                        node.activeKey.length > 1) ||
                      router.pathname === node.activeKey
                        ? style.active
                        : isSafari
                        ? style.top_margin
                        : ''
                    }
                  >
                    <span
                      onClick={() => authHandler('login')}
                      style={{ display: 'flex' }}
                    >
                      <img
                        src={node.iconUrl}
                        alt="icon"
                        width={24}
                        height={24}
                      />
                      <span className={style.title_nav}>{node.title} </span>
                    </span>
                    {node.url == '/collections' && (
                      <small className={style.title_nav}>
                        <ProUpgrade />
                      </small>
                    )}
                  </Link>
                ) : (
                  <Link
                    href={
                      loggedIn
                        ? `${node.url}?sourceCTA=Nav_Bar`
                        : `/sso/login?redirectUrl=${window.location.href}`
                    }
                    className={
                      (router.pathname.includes(node.activeKey) &&
                        node.activeKey.length > 1) ||
                      router.pathname === node.activeKey
                        ? style.active
                        : ''
                    }
                  >
                    <img src={node.iconUrl} alt="icon" width={24} height={24} />
                    <span className={style.title_nav}>{node.title}</span>
                  </Link>
                )}
              </li>
            ))}
          </ul>
          <div className={style.category_name}>ANALYTICAL TOOLS</div>
          <div className={style.category_divider}></div>
          <ul>
            {analyticalToolsNavList?.map((node, index) => (
              <li
                className={style.nav_link}
                key={`headerNavItem-${index}`}
                style={{ height: '54px' }}
              >
                <Link
                  href={
                    loggedIn
                      ? `${node.url}?sourceCTA=Nav_Bar`
                      : `/sso/login?redirectUrl=${window.location.href}`
                  }
                  className={
                    (router.pathname.includes(node.activeKey) &&
                      node.activeKey.length > 1) ||
                    router.pathname === node.activeKey
                      ? style.active
                      : ''
                  }
                >
                  <img src={node.iconUrl} alt="icon" width={24} height={24} />
                  <span className={style.title_nav}>{node.title}</span>
                </Link>
              </li>
            ))}
          </ul>
          <div className={style.category_name}>CAMPAIGN TOOLS</div>
          <div className={style.category_divider}></div>
          <ul>
            {campaignToolsNavList?.map((node, index) => (
              <li
                className={style.nav_link}
                key={`headerNavItem-${index}`}
                style={{ height: '54px' }}
              >
                <Link
                  href={
                    loggedIn
                      ? `${node.url}?sourceCTA=Nav_Bar`
                      : `/sso/login?redirectUrl=${window.location.href}`
                  }
                  className={
                    (router.pathname.includes(node.activeKey) &&
                      node.activeKey.length > 1) ||
                    router.pathname === node.activeKey
                      ? style.active
                      : ''
                  }
                >
                  <img src={node.iconUrl} alt="icon" width={24} height={24} />
                  <span className={style.title_nav}>{node.title}</span>
                </Link>
              </li>
            ))}
          </ul>
          <ul className={`${style.only_mobile} ${style.mob_menu}`}>
            <li className={style.nav_link}>
              <Link href="activity">
                <img
                  src="/images/activity-icon.svg"
                  alt="saas"
                  width={24}
                  height={24}
                />
                <span className={style.title_nav}>Settings</span>
              </Link>
            </li>
            <li className={style.nav_link}>
              <Link href="activity">
                <img
                  src="/images/activity-icon.svg"
                  alt="saas"
                  width={24}
                  height={24}
                />
                <span className={style.title_nav}>Help/Support</span>
              </Link>
            </li>
          </ul>
        </div>
      </nav>

      <ProUpgradeModal />
      <CouponModalTemplate />
    </>
  );
};
export default Header;
