import { useDispatch } from 'react-redux';
import styles from '../../styles/OnboardingModal.module.css';
import { updateToastStatus } from '@/store/general';
import { Dropdown } from 'react-bootstrap';
import { useState } from 'react';

const options = ['Yes', 'No'];

const yesOptions = ['< 15 days', '15-30 days', '> 30 days', 'Not sure yet'];

const QuestionsPage4 = ({ setPageNo, data, setData, webengageEvent }: any) => {
  const dispatch = useDispatch();
  const [showDrpdn, setShowDrpdn] = useState(false);

  return (
    <div>
      <div className={styles.text_container}>
        <div className={styles.title}>
          Are you looking to run an influencer marketing campaign?
        </div>
        <div className={styles.options}>
          {options?.map((option: string, idx: number) => {
            return (
              <div className={styles.option} key={`option${idx}`}>
                <input
                  type="radio"
                  style={{
                    width: 24,
                    height: 24,
                    cursor: 'pointer',
                  }}
                  checked={option === data.usingInflMarketingTool.value}
                  onChange={() =>
                    setData({
                      ...data,
                      usingInflMarketingTool: {
                        ...data.usingInflMarketingTool,
                        value: option,
                      },
                    })
                  }
                />
                <div className={styles.collective_option}>
                  <div className={styles.option_name}>{option}</div>
                  {option === 'Yes' &&
                    data.usingInflMarketingTool.value === 'Yes' && (
                      <Dropdown
                        show={showDrpdn}
                        onToggle={setShowDrpdn}
                        onSelect={(eventKey: any) =>
                          setData({
                            ...data,
                            usingInflMarketingTool: {
                              ...data.usingInflMarketingTool,
                              yesInput: eventKey,
                            },
                          })
                        }
                      >
                        <Dropdown.Toggle
                          variant="success"
                          id="dropdown-basic"
                          className={styles.dropdn}
                        >
                          {data.usingInflMarketingTool.yesInput?.length === 0
                            ? 'When would you like to start?'
                            : data.usingInflMarketingTool.yesInput}
                        </Dropdown.Toggle>
                        <Dropdown.Menu className={styles.drop_mnu}>
                          {yesOptions.map((o: any, idx: any) => (
                            <Dropdown.Item
                              key={idx}
                              className={styles.drop_item}
                              eventKey={o}
                            >
                              {o}
                            </Dropdown.Item>
                          ))}
                        </Dropdown.Menu>
                      </Dropdown>
                    )}
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div className={styles.btns}>
        <div className={styles.back_btn} onClick={() => setPageNo(3)}>
          Back
        </div>
        <div
          className={
            !data.usingInflMarketingTool.value ||
            (data.usingInflMarketingTool.value === 'Yes' &&
              data.usingInflMarketingTool.yesInput.length === 0)
              ? styles.next_btn_disabled
              : styles.next_btn
          }
          onClick={() => {
            if (!data.usingInflMarketingTool.value) {
              dispatch(
                updateToastStatus({
                  status: true,
                  type: 'danger',
                  title: 'Error',
                  desc: 'Please choose an option!',
                })
              );
              return;
            } else if (
              data.usingInflMarketingTool.value === 'Yes' &&
              data.usingInflMarketingTool.yesInput.length === 0
            ) {
              dispatch(
                updateToastStatus({
                  status: true,
                  type: 'danger',
                  title: 'Error',
                  desc: 'Please select an option!',
                })
              );
              return;
            }
            setPageNo(5);
            webengageEvent({
              eventName: 'VS - IM Timeline Selected',
              value: {
                'IM Timeline':
                  data.usingInflMarketingTool.value === 'Yes'
                    ? data.usingInflMarketingTool.yesInput
                    : data.usingInflMarketingTool.value,
              },
            });
          }}
        >
          Next
        </div>
      </div>
    </div>
  );
};

export default QuestionsPage4;
