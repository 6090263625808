import { setIsOnboarded } from '@/modules/sso/store/sso';
import {
  creatorCountSelected,
  imTimelineSelected,
  organisationTypeSelected,
  requirementSelected,
  userRoleSelected,
  wClickTracking,
} from '@/public/js/webengage';
import { updateToastStatus } from '@/store/general';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useDispatch, useSelector } from 'react-redux';
// @ts-ignore
import Account from 'service/AccountService';
// @ts-ignore
import WebengageEvents from 'service/WebengageService';
// @ts-ignore
import AuthService from 'service/AuthService';
import { fbTracking } from '@/public/js/facebookTracking';
const _ = require('lodash');

export const useOnboardingModal = () => {
  const dispatch = useDispatch();
  const [cookies] = useCookies();
  const paid = useSelector((state: any) => state.sso.paid);
  const [pageNo, setPageNo] = useState<number>(1);
  const [data, setData] = useState<any>({
    roleInOrg: null,
    roleInCompany: {
      value: null,
      otherInput: '',
    },
    creatorsWorkLastMonth: null,
    usingInflMarketingTool: {
      value: null,
      yesInput: '',
    },
    help: {
      value: null,
      otherInput: '',
    },
  });

  useEffect(() => {
    const metaViewport = document.querySelector('meta[name="viewport"]');
    if (metaViewport) {
      metaViewport.setAttribute(
        'content',
        'width=device-width, initial-scale=1'
      );
    }
    return () => {
      if (metaViewport) {
        metaViewport.setAttribute('content', 'width=1280');
      }
    };
  }, []);

  const createLead = async () => {
    try {
      const name = cookies?.user?.userAccount?.name?.split(' ') || '';
      let utm: any = {};
      const utmParameters = window.sessionStorage.getItem('utmParameters');
      if (utmParameters) {
        utm = JSON.parse(utmParameters);
      }
      const leadData = await AuthService.createLeadHandler({
        firstName: name[0] || '',
        lastName: name[1] || '',
        phone: cookies?.user?.userAccount?.phone || '',
        email: cookies?.user?.userAccount?.email || '',
        listKey:
          '3z8b2220a2e3699990526ad649f1cbd6710047008c6c92080ba7008ef572b40b9d',
        pageIdentifier: 'ONBOARDING',
        salesQualified: 'true',
        source: `UTM Source: ${utm.source}, UTM Campaign: ${utm.campaign}, UTM Medium: ${utm.medium}, UTM Content: ${utm.content}`,
        orgType: data.roleInOrg || '',
        roleInCompany:
          (data.roleInCompany.value === 'Other'
            ? data.roleInCompany.otherInput
            : data.roleInCompany.value) || '',
        creatorsCollaboratedWith: data.creatorsWorkLastMonth || '',
        onboardingCampaignDate:
          (data.usingInflMarketingTool.value === 'Yes'
            ? data.usingInflMarketingTool.yesInput
            : data.usingInflMarketingTool.value) || '',
        onboardingUseCase:
          (data.help.value === "Other ('Describe your requirement')"
            ? data.help.otherInput
            : data.help.value) || '',
        company: cookies?.user?.userAccount?.brand?.companyName || '',
      });
      if (!leadData?.data?.createLead?.id) {
        console.error({ createLead: data });
      }
    } catch (e: any) {
      console.error({ createLead: e });
    }
  };

  const handleSubmit = async () => {
    webengageEvent({
      eventName: 'VS - Onboarding Requirement Selected',
      value: {
        Requirement:
          data.help.value === "Other ('Describe your requirement')"
            ? data.help.otherInput
            : data.help.value,
        'Organisation type': data.roleInOrg,
        'User Role':
          data.roleInCompany.value === 'Other'
            ? data.roleInCompany.otherInput
            : data.roleInCompany.value,
        'Creator Count': data.creatorsWorkLastMonth,
        'IM timeline':
          data.usingInflMarketingTool.value === 'Yes'
            ? data.usingInflMarketingTool.yesInput
            : data.usingInflMarketingTool.value,
      },
    });

    fbTracking('Onboarding Completed');

    if (
      _.get(cookies, 'user.userAccount.email', '').includes('@gmail') &&
      !_.get(data, 'help.value', '').includes('Not sure, just exploring')
    ) {
      fbTracking('VS - High Intent User');
      wClickTracking('VS - High Intent User', {}, ['lens']);
    }

    try {
      const apiData = await Account.updatePartnerContractDetailsHandler({
        contractType: 'SAAS',
        onboardingStatus: 'ONBOARDED',
      });
      if (apiData?.data?.updatePartnerContractDetails?.status == 'SUCCESS') {
        const redirect = () => {
          dispatch(
            updateToastStatus({
              status: true,
              type: 'success',
              title: 'Success',
              desc: 'Onboarded successfully!',
            })
          );
          let redirectUrl = '';
          if (
            data.help.value ===
            'Discover influencers through a search with filters'
          )
            redirectUrl = '/discovery';
          else if (
            data.help.value ===
            'Explore your competitors, their strategies, and find inspiring advertising insights'
          )
            redirectUrl = '/account-tracking';
          else if (
            data.help.value ===
              'Drive measurable results on my influencer marketing campaigns' ||
            data.help.value ===
              'Amplify my content with the help of influencers' ||
            data.help.value === 'Get reviews created for your products' ||
            data.help.value ===
              'Manage your influencer marketing strategy & execution'
          )
            redirectUrl =
              '/smart-campaigns/create-campaign?requirement=INFLUENCER_MARKETING';
          else if (
            data.help.value === 'Generate report for an existing campaign'
          )
            redirectUrl = '/offline-campaign-reports';
          else if (
            data.help.value ===
              "Analyse the influencer profiles you've already found" ||
            data.help.value === 'Not sure, just exploring' ||
            data.help.value === "Other ('Describe your requirement')"
          )
            redirectUrl = '/';
          if (redirectUrl.length == 0) dispatch(setIsOnboarded(true));
          window.location.replace(
            `${redirectUrl}?sourceCTA=Onboarding_Requirement`
          );
        };
        createLead()
          .then(() => {
            redirect();
          })
          .catch((err: any) => {
            redirect();
          });
      } else {
        dispatch(
          updateToastStatus({
            status: true,
            type: 'danger',
            title: 'Error',
            desc: 'Failed to onboard. Please try again!',
          })
        );
      }
    } catch (e: any) {
      console.log({ useOnboardingModalHandleSubmit: e });
      dispatch(
        updateToastStatus({
          status: true,
          type: 'danger',
          title: 'Error',
          desc: 'Failed to onboard. Please try again!',
        })
      );
    }
  };

  const webengageEvent = (payload: any) => {
    const webEngPayload = {
      userId:
        cookies.user && cookies.user.userAccount
          ? cookies.user.userAccount.id
          : null,
      planType: paid ? 'Paid plan' : 'Free plan',
      ...payload.value,
    };
    if (payload.eventName === 'VS - Organisation Type Selected')
      organisationTypeSelected(webEngPayload);
    else if (payload.eventName === 'VS - User Role Selected')
      userRoleSelected(webEngPayload);
    else if (payload.eventName === 'VS - Creator Count Selected')
      creatorCountSelected(webEngPayload);
    else if (payload.eventName === 'VS - IM Timeline Selected')
      imTimelineSelected(webEngPayload);
    else if (payload.eventName === 'VS - Onboarding Requirement Selected')
      requirementSelected(webEngPayload);
  };

  return {
    pageNo,
    setPageNo,
    data,
    setData,
    handleSubmit,
    webengageEvent,
  };
};
