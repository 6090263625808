import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  platformFilter: 'ALL',
  publisherTypeFilter: 'ALL',
  categoriesFilter: 'All categories',
  languageFilter: 'ALL',
};

export const genreGlobalFiltersSlice = createSlice({
  name: 'genreGlobalFilters',
  initialState,
  reducers: {
    setPlatformFilter: (state, action) => {
      state.platformFilter = action.payload;
    },
    setPublisherTypeFilter: (state, action) => {
      state.publisherTypeFilter = action.payload;
    },
    setCategoriesFilter: (state, action) => {
      state.categoriesFilter = action.payload;
    },
    setLanguageFilter: (state, action) => {
      state.languageFilter = action.payload;
    },
  },
});

export const {
  setPlatformFilter,
  setPublisherTypeFilter,
  setCategoriesFilter,
  setLanguageFilter,
} = genreGlobalFiltersSlice.actions;

export default genreGlobalFiltersSlice.reducer;
