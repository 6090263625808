const general = {
  fbTracking(eventName) {
    console.log(eventName);
    try {
      fbq('trackCustom', eventName);
    } catch {
      console.error('fb tracking error');
    }
  },
};

export const { fbTracking } = general;
