import style from '@/styles/HeaderSearchSuggestion.module.css';
import DefaultImage from '@/components/atoms/DefaultImage';
import { nFormatter } from '@/public/js/util';
import Link from 'next/link';
import Image from 'next/image';

const HeaderSearchSuggestion = ({ suggestionModal }: any) => {
  return (
    <>
      <div className={style.search_sugg_block}>
        <div className={style.search_popup}>
          {suggestionModal &&
            suggestionModal.data &&
            suggestionModal.data.length > 0 &&
            suggestionModal.data.map((node: any, index: number) => (
              <Link
                href={`/influencer/${node.code}?sourceCTA=Header_Search_Suggestion`}
                target="_blank"
                key={`headerSearchSuggestionItem-${index}`}
              >
                <div
                  key={`headerSearchSuggestionItem-${index}`}
                  className={`${style.popup_list} d-flex align-items-center`}
                >
                  <ul style={{ width: '95%' }}>
                    <li>
                      <div className={style.inf_pic}>
                        <div
                          className={style.inf_pic_cont}
                          style={{ width: '43px', height: '43px' }}
                        >
                          <DefaultImage
                            key={`searchSuggestion-defaultImage-${index}`}
                            width="43"
                            height="43"
                            name={node.name}
                            index={index}
                            fontSize="20px"
                            thumbnail={node.thumbnail}
                            borderRadius="50%"
                            className={style.img_bg}
                          />
                        </div>
                        {node.platform === 'INSTAGRAM' && (
                          <span className={`${style.inf_soc} ${style.insta}`}>
                            <i className="bi bi-instagram"></i>
                          </span>
                        )}
                        {node.platform === 'YOUTUBE' && (
                          <span className={`${style.inf_soc} ${style.ytube}`}>
                            <i className="bi bi-play-fill"></i>
                          </span>
                        )}
                      </div>
                    </li>
                    <li>
                      <p>
                        {node.name}{' '}
                        {node.isVerified && (
                          <i
                            className={`bi bi-patch-check-fill ${style.verify}`}
                          ></i>
                        )}
                        <br></br>
                        <span>
                          {node.platform === 'INSTAGRAM' && node.handle
                            ? node.handle
                            : node.platform === 'YOUTUBE' && node.username
                            ? node.username
                            : '--'}
                        </span>
                      </p>
                    </li>
                    <li>
                      <p className={style.info}>
                        {nFormatter(node.metrics.followers)}
                        {node.platform === 'INSTAGRAM'
                          ? ' followers'
                          : ' subscribers'}
                      </p>
                    </li>
                  </ul>
                  <i className={`bi bi-chevron-right`}></i>
                </div>
              </Link>
            ))}

          {!suggestionModal.loading &&
            suggestionModal.data &&
            suggestionModal.data.length > 0 && (
              <p className={style.moresrh}>
                <img
                  src="/images/user-search.svg"
                  alt="sss"
                  width={35}
                  height={35}
                />
                <Link
                  href={`/discovery?search_phrase=${suggestionModal.searchPhrase}`}
                >
                  <span>View all search results</span>
                  {/* <br></br>10 results available */}
                </Link>
              </p>
            )}

          {suggestionModal.loading && (
            <p
              style={{
                fontSize: '12px',
                marginBottom: '0px',
                padding: '10px 0',
                textAlign: 'center',
              }}
            >
              Searching...
            </p>
          )}
          {!suggestionModal.loading &&
            suggestionModal.data &&
            suggestionModal.data.length == 0 && (
              <p
                style={{
                  fontSize: '12px',
                  marginBottom: '0px',
                  padding: '10px 0',
                  textAlign: 'center',
                }}
              >
                No result found!
              </p>
            )}
        </div>
      </div>
    </>
  );
};

export default HeaderSearchSuggestion;
