import { createSlice } from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';

const initialState = {
  isShared: false,
  paid: true,
  selectedPlatform: null,
  platforms: {
    INSTAGRAM: {
      basicInfo: {
        loading: true,
        data: null,
      },
      overview: {
        loading: true,
        data: null,
      },
      audience: {
        loading: true,
        data: null,
      },
      growth: {
        loading: true,
        data: null,
      },
      content: {
        loading: true,
        data: null,
      },
      hashtags: {
        loading: true,
        data: null,
      },
      similarAccounts: {
        loading: true,
        data: null,
      },
    },
    YOUTUBE: {
      basicInfo: {
        loading: true,
        data: null,
      },
      overview: {
        loading: true,
        data: null,
      },
      audience: {
        loading: true,
        data: null,
      },
      growth: {
        loading: true,
        data: null,
      },
      content: {
        loading: true,
        data: null,
      },
      hashtags: {
        loading: true,
        data: null,
      },
      similarAccounts: {
        loading: true,
        data: null,
      },
    },
  },
  lockedProfile: {
    INSTAGRAM: true,
    YOUTUBE: true,
  },
  showAdvAnalytics: {
    INSTAGRAM: false,
    YOUTUBE: false,
  },
};

export const influencerReport = createSlice({
  name: 'influencerReport',
  initialState,
  reducers: {
    setIsShared(state, action) {
      state.isShared = action.payload;
    },
    setData(state, action) {
      if (action.payload.platform)
        state.selectedPlatform = action.payload.platform;

      if (action.payload.type == 'basicInfo') {
        state.platforms['INSTAGRAM'].basicInfo = action.payload.details;
        state.platforms['YOUTUBE'].basicInfo = action.payload.details;
      } else if (action.payload.type == 'overview') {
        state.platforms[state.selectedPlatform.platform].overview =
          action.payload.details;
      } else if (action.payload.type == 'audience') {
        state.platforms[state.selectedPlatform.platform].audience =
          action.payload.details;
      } else if (action.payload.type == 'growth') {
        state.platforms[state.selectedPlatform.platform].growth =
          action.payload.details;
      } else if (action.payload.type == 'content') {
        state.platforms[state.selectedPlatform.platform].content =
          action.payload.details;
      } else if (action.payload.type == 'hashtags') {
        state.platforms[state.selectedPlatform.platform].hashtags =
          action.payload.details;
      } else if (action.payload.type == 'similarAccounts') {
        state.platforms[state.selectedPlatform.platform].similarAccounts =
          action.payload.details;
      }
    },
    switchPlatform(state, action) {
      state.selectedPlatform = action.payload;
    },
    setLockedProfile(state, action) {
      state.lockedProfile[action.payload.platform] = action.payload.value;
    },
    setShowAdvAnalytics(state, action) {
      state.showAdvAnalytics[action.payload.platform] = action.payload.value;
    },

    // Special reducer for hydrating the state
    extraReducers: {
      [HYDRATE]: (state, action) => {
        return {
          ...state,
          ...action.payload.sso,
        };
      },
    },
  },
});

export const {
  setData,
  switchPlatform,
  setLockedProfile,
  setShowAdvAnalytics,
  setIsShared,
} = influencerReport.actions;

export default influencerReport.reducer;
