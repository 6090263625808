import { useOnboardingModal } from '../../hooks/useOnboardingModal';
import styles from '../../styles/OnboardingModal.module.css';
import QuestionsPage0 from '../atoms/QuestionsPage0';
import QuestionsPage1 from '../atoms/QuestionsPage1';
import QuestionsPage2 from '../atoms/QuestionsPage2';
import QuestionsPage3 from '../atoms/QuestionsPage3';
import QuestionsPage4 from '../atoms/QuestionsPage4';
import QuestionsPage5 from '../atoms/QuestionsPage5';

const OnboardingModal = () => {
  const { pageNo, setPageNo, data, setData, handleSubmit, webengageEvent } =
    useOnboardingModal();

  return (
    <>
      <div className={`modal-overlay ${styles.modal_overlay}`}></div>
      <div
        className={`modal show fade ${styles.modal_container}`}
        role="dialog"
      >
        <div
          className={`modal-dialog modal-lg modal-dialog-centered ${styles.modal_dialog}`}
          role="document"
        >
          <div className={`modal-content text-start ${styles.modal_content}`}>
            <div className={styles.main_container}>
              <div className={styles.left_part}>
                <img
                  src="/images/onboarding.svg"
                  alt="saas"
                  width={'602px'}
                  height={'auto'}
                />
              </div>
              <div className={styles.right_part}>
                {pageNo !== 0 && (
                  <div className={styles.progress_tracker_container}>
                    <div className={styles.progress_tracker}>
                      <span
                        className={styles.progress_completed}
                        style={{ width: `${pageNo * 20}%` }}
                      />
                    </div>
                    <div className={styles.progress_value}>{pageNo * 20}%</div>
                  </div>
                )}
                {pageNo === 0 && <QuestionsPage0 setPageNo={setPageNo} />}
                {pageNo === 1 && (
                  <QuestionsPage1
                    setPageNo={setPageNo}
                    data={data}
                    setData={setData}
                    webengageEvent={webengageEvent}
                  />
                )}
                {pageNo === 2 && (
                  <QuestionsPage2
                    setPageNo={setPageNo}
                    data={data}
                    setData={setData}
                    webengageEvent={webengageEvent}
                  />
                )}
                {pageNo === 3 && (
                  <QuestionsPage3
                    setPageNo={setPageNo}
                    data={data}
                    setData={setData}
                    webengageEvent={webengageEvent}
                  />
                )}
                {pageNo === 4 && (
                  <QuestionsPage4
                    setPageNo={setPageNo}
                    data={data}
                    setData={setData}
                    webengageEvent={webengageEvent}
                  />
                )}
                {pageNo === 5 && (
                  <QuestionsPage5
                    setPageNo={setPageNo}
                    data={data}
                    setData={setData}
                    handleSubmit={handleSubmit}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.modal_mobile_container}>
        <img
          src="/images/onboarding_mobile.svg"
          alt="saas"
          width={'100%'}
          height={'auto'}
        />
        <div className={styles.main_container}>
          <div className={styles.right_part}>
            {pageNo !== 0 && (
              <div className={styles.progress_tracker_container}>
                <div className={styles.progress_tracker}>
                  <span
                    className={styles.progress_completed}
                    style={{ width: `${pageNo * 20}%` }}
                  />
                </div>
                <div className={styles.progress_value}>{pageNo * 20}%</div>
              </div>
            )}
            {pageNo === 0 && <QuestionsPage0 setPageNo={setPageNo} />}
            {pageNo === 1 && (
              <QuestionsPage1
                setPageNo={setPageNo}
                data={data}
                setData={setData}
                webengageEvent={webengageEvent}
              />
            )}
            {pageNo === 2 && (
              <QuestionsPage2
                setPageNo={setPageNo}
                data={data}
                setData={setData}
                webengageEvent={webengageEvent}
              />
            )}
            {pageNo === 3 && (
              <QuestionsPage3
                setPageNo={setPageNo}
                data={data}
                setData={setData}
                webengageEvent={webengageEvent}
              />
            )}
            {pageNo === 4 && (
              <QuestionsPage4
                setPageNo={setPageNo}
                data={data}
                setData={setData}
                webengageEvent={webengageEvent}
              />
            )}
            {pageNo === 5 && (
              <QuestionsPage5
                setPageNo={setPageNo}
                data={data}
                setData={setData}
                handleSubmit={handleSubmit}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};
export default OnboardingModal;
