import { createSlice } from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';

const initialState = {
  toastModals: [
    {
      showToast: false,
      message: {
        title: 'Title',
        desc: 'Desc',
      },
      type: 'info',
      //     'success',
      //     'danger',
      //     'warning',
      //     'info',
    },
  ],
  activityId: {
    ig: null,
    yt: null,
  },
};

export const generalSlice = createSlice({
  name: 'general',
  initialState,
  reducers: {
    updateToastStatus(state, action) {
      const toastModal = {
        showToast: false,
        message: {
          title: 'Title',
          desc: 'Desc',
        },
        type: 'info',
      };
      toastModal.showToast = action.payload.status;
      if (action.payload.title) {
        toastModal.message.title = action.payload.title;
        toastModal.message.desc = action.payload.desc;
      }
      if (action.payload.delay) {
        toastModal.delay = action.payload.delay;
      }

      if (action.payload.type) toastModal.type = action.payload.type;
      if (action.payload.index || action.payload.index === 0) {
        state.toastModals.splice(action.payload.index, 1);
      } else {
        state.toastModals.push(toastModal);
      }
    },
    handleActivityId(state, action) {
      action.payload.activityPlatform === 'INSTAGRAM'
        ? (state.activityId.ig = action.payload.activityId)
        : (state.activityId.yt = action.payload.activityId);
    },
    resetAllActivityIds(state, action) {
      state.activityId.ig = null;
      state.activityId.yt = null;
    },

    // Special reducer for hydrating the state
    extraReducers: {
      [HYDRATE]: (state, action) => {
        return {
          ...state,
          ...action.payload.general,
        };
      },
    },
  },
});

export const { updateToastStatus, handleActivityId, resetAllActivityIds } =
  generalSlice.actions;

export default generalSlice.reducer;
