import { useCookies } from 'react-cookie';
import styles from '../../styles/OnboardingModal.module.css';
import { useEffect } from 'react';

const QuestionsPage0 = ({ setPageNo, data, setData }: any) => {
  const [cookies] = useCookies();
  const name = cookies.user?.userAccount?.name || '';

  useEffect(() => {
    const timer = setTimeout(() => {
      setPageNo(1);
    }, 3000);
    return () => {
      if (timer) clearTimeout(timer);
    };
  }, []);

  return (
    <div>
      <div className={styles.welcome_text_container}>
        <div className={styles.welcome_title}>Welcome {name}</div>
        <div className={styles.welcome_desc}>
          Let's get to know you. This will help us tailor your experience as per
          your needs.
        </div>
      </div>
    </div>
  );
};

export default QuestionsPage0;
