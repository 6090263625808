import { useDispatch } from 'react-redux';
import styles from '../../styles/OnboardingModal.module.css';
import { updateToastStatus } from '@/store/general';

const options = ['None', '1-10', '11-50', '51-100', '101-500', '501+'];

const QuestionsPage3 = ({ setPageNo, data, setData, webengageEvent }: any) => {
  const dispatch = useDispatch();

  return (
    <div>
      <div className={styles.text_container}>
        <div className={styles.title}>
          How many creators did you work with last month?
        </div>
        <div className={styles.options}>
          {options?.map((option: string, idx: number) => {
            return (
              <div className={styles.option} key={`option${idx}`}>
                <input
                  type="radio"
                  style={{
                    width: 24,
                    height: 24,
                    cursor: 'pointer',
                  }}
                  checked={option === data.creatorsWorkLastMonth}
                  onChange={() =>
                    setData({
                      ...data,
                      creatorsWorkLastMonth: option,
                    })
                  }
                />
                <div className={styles.option_name}>{option}</div>
              </div>
            );
          })}
        </div>
      </div>
      <div className={styles.btns}>
        <div className={styles.back_btn} onClick={() => setPageNo(2)}>
          Back
        </div>
        <div
          className={
            !data.creatorsWorkLastMonth
              ? styles.next_btn_disabled
              : styles.next_btn
          }
          onClick={() => {
            if (!data.creatorsWorkLastMonth) {
              dispatch(
                updateToastStatus({
                  status: true,
                  type: 'danger',
                  title: 'Error',
                  desc: 'Please choose an option!',
                })
              );
              return;
            }

            setPageNo(4);
            webengageEvent({
              eventName: 'VS - Creator Count Selected',
              value: { 'Creator Count': data.creatorsWorkLastMonth },
            });
          }}
        >
          Next
        </div>
      </div>
    </div>
  );
};

export default QuestionsPage3;
