import { createSlice } from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';
import { getPaidRestrictionsThunk } from './middlewares';

const initialState = {
  loggedIn: false,
  paid: false,
  credsLoginEnabled: false,
  partnerUserProfile: null,
  permissions: [],
  modalType: '',
  paidRestrictions: {
    loading: true,
    data: {
      beginsOn: 0,
      expiresOn: 0,
      discovery: {
        reportCountLeft: 0,
        reportCountLimit: 0,
      },
      influencerProfile: {
        reportCountLeft: 0,
        reportCountLimit: 0,
      },
      leaderboard: {
        all: {
          enabledCategories: [],
          enabledLanguages: [],
        },
        instagram: {
          enabledCategories: [],
          enabledLanguages: [],
        },
        youtube: {
          enabledCategories: [],
          enabledLanguages: [],
        },
      },
      campaignReport: {
        postCountLeft: 0,
        postCountLimit: 0,
      },
      accountTracking: {
        accountCountLeft: 0,
        accountCountLimit: 0,
      },
      genreInsights: {
        modulePaid: false,
      },
      topicResearch: {
        modulePaid: false,
      },
    },
  },
  paidExpired: true,
  isOnboarded: true,
};

export const ssoSlice = createSlice({
  name: 'sso',
  initialState,
  reducers: {
    setPermission(state, action) {
      state.permissions = action.payload;
    },
    setForShared(state, action) {
      state.paid = action.payload.paid;
      state.paidExpired = action.payload.paidExpired;
    },
    setLoggedIn(state, action) {
      state.loggedIn = action.payload.loggedIn;
      state.paid = action.payload.paid;
      state.partnerUserProfile = action.payload.partnerUserProfile;
      state.credsLoginEnabled = action.payload.credsLoginEnabled;
    },
    setModalType(state, action) {
      state.modalType = action.payload;
    },
    setLoadingPaidRestrictions(state, action) {
      state.paidRestrictions.loading = action.payload;
    },
    setIsOnboarded(state, action) {
      state.isOnboarded = action.payload;
    },

    // Special reducer for hydrating the state
    extraReducers: {
      [HYDRATE]: (state, action) => {
        return {
          ...state,
          ...action.payload.sso,
        };
      },
    },
  },
  extraReducers: {
    [getPaidRestrictionsThunk.pending]: (state, { payload }) => {},
    [getPaidRestrictionsThunk.fulfilled]: (state, { payload }) => {
      state.paidRestrictions.loading = false;
      state.paidRestrictions.data = payload.paidRestrictions;
      state.paidExpired = payload.paidExpired;
    },
    [getPaidRestrictionsThunk.rejected]: (state, { payload }) => {},
  },
});

export const {
  setPermission,
  setLoggedIn,
  setModalType,
  setLoadingPaidRestrictions,
  setIsOnboarded,
  setForShared,
} = ssoSlice.actions;

export default ssoSlice.reducer;
