import { createAsyncThunk } from '@reduxjs/toolkit';
import { RepositoryFactory } from '@/apiRepository/RepositoryFactory';
const AuthRepository = RepositoryFactory.get('auth');
import { get } from 'lodash';
import moment from 'moment';

const t1 = {
  status: {
    status: 'SUCCESS',
    message: 'Profiles successfully retrieved',
    filteredCount: 1,
  },
  partner: [
    {
      id: 7,
      name: 'Alkmene',
      status: 'ACTIVE',
      contracts: [
        {
          id: 754,
          partnerId: 7,
          contractType: 'INFLUENCER_MARKETING',
          status: 'ACTIVE',
          onboardingStatus: 'IN_PROGRESS',
          plan: 'FREE',
          startTime: 1689166320000,
          endTime: 1722343920000,
          configurations: [
            {
              namespace: 'DISCOVERY',
              configuration: [
                {
                  key: 'USAGE',
                  type: 'COUNTER',
                  value: 10000,
                  consumed: 0,
                  frequency: 'monthly',
                },
              ],
            },
            {
              namespace: 'LEADERBOARD',
              configuration: [
                {
                  key: 'INSTAGRAM.CATEGORIES',
                  values: ['Beauty', 'Automobile'],
                  value: 0,
                  consumed: 0,
                },
                {
                  key: 'INSTAGRAM.LANGUAGES',
                  values: ['en', 'hi'],
                  value: 0,
                  consumed: 0,
                },
                {
                  key: 'YOUTUBE.CATEGORIES',
                  values: ['Beauty', 'Sports'],
                  value: 0,
                  consumed: 0,
                },
                {
                  key: 'YOUTUBE.LANGUAGES',
                  values: ['gj', 'ma'],
                  value: 0,
                  consumed: 0,
                },
                {
                  key: 'ALL.CATEGORIES',
                  values: ['Beauty', 'Sports'],
                  value: 0,
                  consumed: 0,
                },
                {
                  key: 'ALL.LANGUAGES',
                  values: ['gj', 'ma'],
                  value: 0,
                  consumed: 0,
                },
                {
                  key: 'USAGE',
                  type: 'COUNTER',
                  value: 10000,
                  consumed: 0,
                  frequency: 'monthly',
                },
              ],
            },
            {
              namespace: 'PROFILE_PAGE',
              configuration: [
                {
                  key: 'USAGE',
                  type: 'COUNTER',
                  value: 10000,
                  consumed: 0,
                  frequency: 'monthly',
                },
              ],
            },
          ],
        },
      ],
    },
  ],
};
const initialPaidRestrictions = {
  beginsOn: 0,
  expiresOn: 0,
  discovery: {
    reportCountLeft: 0,
    reportCountLimit: 0,
  },
  influencerProfile: {
    reportCountLeft: 0,
    reportCountLimit: 0,
  },
  leaderboard: {
    all: {
      enabledCategories: [],
      enabledLanguages: [],
    },
    instagram: {
      enabledCategories: [],
      enabledLanguages: [],
    },
    youtube: {
      enabledCategories: [],
      enabledLanguages: [],
    },
  },
  campaignReport: {
    postCountLeft: 0,
    postCountLimit: 0,
  },
  accountTracking: {
    accountCountLeft: 0,
    accountCountLimit: 0,
  },
  genreInsights: {
    modulePaid: false,
  },
  topicResearch: {
    modulePaid: false,
  },
};

export const getPaidRestrictionsThunk = createAsyncThunk(
  'sso/getPaidRestrictions',
  async ({ brandId }: any, thunkAPI) => {
    try {
      const { data } = await AuthRepository.getPaidRestrictions({
        id: brandId ? brandId : -1,
      });
      if (data.status?.status === 'SUCCESS' && data.partner) {
        const configurations = get(
          data,
          'partner[0].contracts[0].configurations',
          []
        );
        const paidRestrictions = {
          ...initialPaidRestrictions,
          beginsOn: Number(get(data, 'partner[0].contracts[0].startTime', 0)),
          expiresOn: Number(get(data, 'partner[0].contracts[0].endTime', 0)),
        };
        configurations.forEach((config: any) => {
          if (config.namespace === 'DISCOVERY') {
            let reportCountLeft = 0;
            let reportCountLimit = 0;
            config.configuration.forEach((obj: any) => {
              if (obj.key === 'USAGE') {
                let tReportCountLeft =
                  get(obj, 'value', 0) - get(obj, 'consumed', 0);
                reportCountLeft = tReportCountLeft < 0 ? 0 : tReportCountLeft;
                reportCountLimit = get(obj, 'value', 0);
              }
            });
            paidRestrictions['discovery'] = {
              reportCountLeft,
              reportCountLimit,
            };
          }
          if (config.namespace === 'PROFILE_PAGE') {
            let reportCountLeft = 0;
            let reportCountLimit = 0;
            config.configuration.forEach((obj: any) => {
              if (obj.key === 'USAGE') {
                let tReportCountLeft =
                  get(obj, 'value', 0) - get(obj, 'consumed', 0);
                reportCountLeft = tReportCountLeft < 0 ? 0 : tReportCountLeft;
                reportCountLimit = get(obj, 'value', 0);
              }
            });
            paidRestrictions['influencerProfile'] = {
              reportCountLeft,
              reportCountLimit,
            };
          }
          if (config.namespace === 'LEADERBOARD') {
            let allEnabledCategories: any = ['INCLUDE_ALL'];
            let allEnabledLanguages: any = ['INCLUDE_ALL'];
            let igEnabledCategories: any = ['INCLUDE_ALL'];
            let igEnabledLanguages: any = ['INCLUDE_ALL'];
            let ytEnabledCategories: any = ['INCLUDE_ALL'];
            let ytEnabledLanguages: any = ['INCLUDE_ALL'];
            const configConfiguration = get(config, 'configuration', []);
            configConfiguration?.forEach((obj: any) => {
              if (obj.key === 'ALL.CATEGORIES')
                allEnabledCategories = obj.values;
              else if (obj.key === 'ALL.LANGUAGES')
                allEnabledLanguages = obj.values;
              if (obj.key === 'INSTAGRAM.CATEGORIES')
                igEnabledCategories = obj.values;
              else if (obj.key === 'INSTAGRAM.LANGUAGES')
                igEnabledLanguages = obj.values;
              else if (obj.key === 'YOUTUBE.CATEGORIES')
                ytEnabledCategories = obj.values;
              else if (obj.key === 'YOUTUBE.LANGUAGES')
                ytEnabledLanguages = obj.values;
            });
            paidRestrictions['leaderboard'] = {
              all: {
                enabledCategories: allEnabledCategories,
                enabledLanguages: allEnabledLanguages,
              },
              instagram: {
                enabledCategories: igEnabledCategories,
                enabledLanguages: igEnabledLanguages,
              },
              youtube: {
                enabledCategories: ytEnabledCategories,
                enabledLanguages: ytEnabledLanguages,
              },
            };
          }
          if (config.namespace === 'CAMPAIGN_REPORT') {
            let postCountLeft = 0;
            let postCountLimit = 0;
            config.configuration.forEach((obj: any) => {
              if (obj.key === 'USAGE') {
                let tPostCountLeft =
                  get(obj, 'value', 0) - get(obj, 'consumed', 0);
                postCountLeft = tPostCountLeft < 0 ? 0 : tPostCountLeft;
                postCountLimit = get(obj, 'value', 0);
              }
            });
            paidRestrictions['campaignReport'] = {
              postCountLeft,
              postCountLimit,
            };
          }
          if (config.namespace === 'ACCOUNT_TRACKING') {
            let accountCountLeft = 0;
            let accountCountLimit = 0;
            config.configuration.forEach((obj: any) => {
              if (obj.key === 'USAGE') {
                let tAccountCountLeft =
                  get(obj, 'value', 0) - get(obj, 'consumed', 0);
                accountCountLeft =
                  tAccountCountLeft < 0 ? 0 : tAccountCountLeft;
                accountCountLimit = get(obj, 'value', 0);
              }
            });
            paidRestrictions['accountTracking'] = {
              accountCountLeft,
              accountCountLimit,
            };
          }
          if (config.namespace === 'GENRE_INSIGHTS') {
            paidRestrictions['genreInsights'] = {
              modulePaid: get(config, 'planType', 'FREE') === 'PAID',
            };
          }
          if (config.namespace === 'TOPIC_RESEARCH') {
            paidRestrictions['topicResearch'] = {
              modulePaid: get(config, 'planType', 'FREE') === 'PAID',
            };
          }
        });
        let paidExpired = true;
        if (
          paidRestrictions.beginsOn < moment().unix() * 1000 &&
          paidRestrictions.expiresOn > moment().unix() * 1000
        )
          paidExpired = false;
        return { paidRestrictions, paidExpired: paidExpired };
      } else {
        console.error('getPaidRestrictionsThunk:', { data });
        return { paidRestrictions: initialPaidRestrictions, paidExpired: true };
      }
    } catch (e) {
      console.error('getPaidRestrictionsThunk error:', { e });
      return { paidRestrictions: initialPaidRestrictions, paidExpired: true };
    }
  }
);
