import { setModalType } from '@/modules/sso/store/sso';
import style from '../../styles/CouponModalTemplate.module.css';
import Link from 'next/link';
import { useDispatch, useSelector } from 'react-redux';
import { useCookies } from 'react-cookie';
import { createSmartCampaignClicked } from '@/public/js/webengage';

const CouponModalTemplate = () => {
  const dispatch = useDispatch();
  const [cookies] = useCookies(['user']);
  const loggedIn = useSelector((state: any) => state.sso.loggedIn);
  const paid = useSelector((state: any) => state.sso.paid);

  const handleClaim = (e: any) => {
    e.preventDefault();
    if (loggedIn)
      window.location.href =
        '/smart-campaigns/create-campaign?freeCampaign=true';
    else {
      authHandler('signup');
    }
    const payload: any = {
      userId: cookies?.user?.userAccount?.id || null,
      planType: paid ? 'Paid plan' : 'Free plan',
      brandId: cookies?.user?.userAccount?.brand?.id || null,
      isFreeCampaign: true,
    };
    createSmartCampaignClicked(payload);
  };
  const authHandler = (type: string) => {
    const redirectUrl = window.location.href;
    window.location.href = `${window.location.origin}/sso/${type}?redirectUrl=${redirectUrl}`;
  };
  return (
    <>
      <div
        className="modal fade"
        id="ModalRewardLadderCoupon"
        role="dialog"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-lg modal-dialog-centered"
          role="document"
        >
          <div className={`modal-content text-start ${style.modal_height}`}>
            <i
              className={`bi bi-x ${style.close_modal}`}
              data-bs-dismiss="modal"
            ></i>
            <div className={`modal-body ${style.coupon_modal}`}>
              <div style={{ display: 'flex' }}>
                <div style={{ width: '47%' }}>
                  <div className={style.login_container}>
                    <div style={{ padding: '34px 34px' }}>
                      <div className={style.left_title}>
                        Get Free Campaign worth ₹10,000
                      </div>
                      <div className={style.left_desc}>
                        A pre made campaign is ready & waiting to go live
                      </div>
                      <div
                        className={style.left_btn}
                        data-bs-dismiss="modal"
                        onClick={(e) => handleClaim(e)}
                      >
                        Claim now
                      </div>
                    </div>
                    <div className={style.vid_login_dv}>
                      <Link href="/">
                        <img
                          src="/images/reward-ladder-coupon.svg"
                          alt="saas"
                          width={376}
                          height={209}
                          style={{ bottom: 0, position: 'absolute' }}
                        />
                      </Link>
                    </div>
                  </div>
                </div>
                <div className={style.right_section}>
                  <div>
                    <div className={style.right_section_title}>
                      <i
                        className="bi bi-circle-fill"
                        style={{
                          color: '#FFC400',
                          fontSize: 10,
                          bottom: '2px',
                          position: 'relative',
                          marginRight: 10,
                        }}
                      ></i>
                      Guaranteed results
                    </div>
                    <div className={style.right_section_desc}>
                      Drive predictable returns by achieving estimated targets
                      for your spends
                    </div>
                  </div>
                  <div>
                    <div className={style.right_section_title}>
                      <i
                        className="bi bi-circle-fill"
                        style={{
                          color: '#FFC400',
                          fontSize: 10,
                          bottom: '2px',
                          position: 'relative',
                          marginRight: 10,
                        }}
                      ></i>
                      Hassle-free execution
                    </div>
                    <div className={style.right_section_desc}>
                      Allow our intelligent systems, trained on 1000s of
                      campaigns, to optimise your returns
                    </div>
                  </div>
                  <div>
                    <div className={style.right_section_title}>
                      <i
                        className="bi bi-circle-fill"
                        style={{
                          color: '#FFC400',
                          fontSize: 10,
                          bottom: '2px',
                          position: 'relative',
                          marginRight: 10,
                        }}
                      ></i>
                      4x more engagement
                    </div>
                    <div className={style.right_section_desc}>
                      Leverage our community of nano & micro creators to spread
                      your word to your target audience
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="closemodalTriggerRewardLadderCoupon"
        data-bs-dismiss="modal"
        data-bs-target="#ModalRewardLadderCoupon"
      ></div>
    </>
  );
};

export default CouponModalTemplate;
