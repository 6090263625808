
require("./../node_modules/@module-federation/nextjs-mf/src/include-defaults.js");
import '@/styles/globals.css';
import 'bootstrap/dist/css/bootstrap.css';
import { Provider } from 'react-redux';
import { wrapper } from '@/store/index';
import DefaultLayout from '@/layouts/default';
import { useEffect, useState } from 'react';
import NProgress from 'nprogress';
import Router from 'next/router';
import { CookiesProvider } from 'react-cookie';
import Script from 'next/script';
import { useRouter } from 'next/router';
import EmptyLayout from '@/layouts/empty';

Router.events.on('routeChangeStart', () => {
  NProgress.start();
});

Router.events.on('routeChangeComplete', () => {
  NProgress.done();
});

Router.events.on('routeChangeError', () => {
  NProgress.done();
});

export const getServerSideProps = () => {
  return { props: { whitelist: true } };
};

export default function App({ Component, ...rest }: any) {
  const router = useRouter();
  const { store } = wrapper.useWrappedStore(rest);
  const { pageProps } = rest;
  const [renderComponents, setRenderComponents] = useState(false);

  useEffect(() => {
    require('bootstrap/dist/js/bootstrap.bundle.min.js');

    if (window.localStorage.getItem('deviceId')) setRenderComponents(true);
    else
      window.addEventListener('message', (event) => {
        if (event.data && event.data.message == 'deviceIdLoaded') {
          if (window.localStorage.getItem('deviceId')) {
            setRenderComponents(true);
            window.removeEventListener('message', () => {});
          } else {
            window.location.reload();
          }
        }
      });
  }, []);

  let renderWithLayout: any = null;
  // NOTE:If you create a new layout make sure you wrap it with paid restrictions logic

  if (
    router.pathname.includes('/sso/') ||
    router.pathname.includes('/landing-page')
  ) {
    renderWithLayout = function (page: any) {
      return <EmptyLayout>{page}</EmptyLayout>;
    };
  } else {
    renderWithLayout =
      Component.getLayout ||
      function (page: any) {
        return <DefaultLayout>{page}</DefaultLayout>;
      };
  }

  const renderWithStore = function (page: any) {
    return (
      <CookiesProvider>
        {renderComponents ? (
          <Provider store={store}>{renderWithLayout(page)}</Provider>
        ) : (
          ''
        )}
        <Script
          src="https://www.googletagmanager.com/gtag/js?id=G-KCKZL6PX6V"
          strategy="afterInteractive"
        />
        <Script id="google-analytics" strategy="afterInteractive">
          {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
        
          gtag('config', 'G-KCKZL6PX6V');
        `}
        </Script>
        <Script>
          {`if(!window.localStorage.getItem("deviceId")) {
            try {
              const fpPromise = import(
                "https://d1vvbbkczuybht.cloudfront.net/fingerprintjs/v3"
              ).then(FingerprintJS => FingerprintJS.load());
              // Get the visitor identifier when you need it.
              fpPromise
                .then(fp => fp.get())
                .then(result => {
                  // This is the visitor identifier:
                  const visitorId = result.visitorId;
                    window.localStorage.setItem("deviceId", visitorId);
                    window.postMessage({message: "deviceIdLoaded"}, "*");
                })
                .catch(error => {
                  window.localStorage.setItem("deviceId", Math.random().toString(36).slice(2));
                  window.postMessage({message: "deviceIdLoaded"}, "*");
                  return 
                })
            } catch {
              window.localStorage.setItem("deviceId", Math.random().toString(36).slice(2));
              window.postMessage({message: "deviceIdLoaded"}, "*");
            }
            }`}
        </Script>
        <Script id="_webengage_script_tag" type="text/javascript">
          {`
            var webengage
            !(function (w, e, b, n, g) {
              function o(e, t) {
                e[t[t.length - 1]] = function () {
                  r.__queue.push([t.join('.'), arguments])
                }
              }
              var i,
                s,
                r = w[b],
                z = ' ',
                l = 'init options track screen onReady'.split(z),
                a = 'feedback survey notification'.split(z),
                c = 'options render clear abort'.split(z),
                p = 'Open Close Submit Complete View Click'.split(z),
                u = 'identify login logout setAttribute'.split(z)
              if (!r || !r.__v) {
                for (
                  w[b] = r = { __queue: [], __v: '6.0', user: {} }, i = 0;
                  i < l.length;
                  i++
                )
                  o(r, [l[i]])
                for (i = 0; i < a.length; i++) {
                  for (r[a[i]] = {}, s = 0; s < c.length; s++)
                    o(r[a[i]], [a[i], c[s]])
                  for (s = 0; s < p.length; s++) o(r[a[i]], [a[i], 'on' + p[s]])
                }
                for (i = 0; i < u.length; i++) o(r.user, ['user', u[i]])
                setTimeout(function () {
                  var f = e.createElement('script'),
                    d = e.getElementById('_webengage_script_tag')
                  ;(f.type = 'text/javascript'),
                    (f.async = !0),
                    (f.src =
                      ('https:' == e.location.protocol
                        ? 'https://ssl.widgets.webengage.com'
                        : 'http://cdn.widgets.webengage.com') +
                      '/js/webengage-min-v-6.0.js'),
                    d.parentNode.insertBefore(f, d)
                })
              }
            })(window, document, 'webengage')
            webengage.init('311c4bbd')
          `}
          {/* stage - 58add271 */}
        </Script>
        <Script id="utmParameters">
          {`
            let params = new URLSearchParams(window.location.search)
            if (
              params.get('utm_source') ||
              params.get('utm_medium') ||
              params.get('utm_campaign')
            ) {
              let utm = {
                source: params.get('utm_source') || null,
                medium: params.get('utm_medium') || null,
                campaign: params.get('utm_campaign') || null,
                term: params.get('utm_term') || null,
                content: params.get('utm_content') || null,
              }

              window.sessionStorage.setItem('utmParameters', JSON.stringify(utm))
              setWithExpiry('utmParameters', JSON.stringify(utm), 2592000000)
              // 2,592,000,000 - 30days epoch difference in mm
            } else if (getWithExpiry('utmParameters')) {
              let utmParameters = JSON.parse(getWithExpiry('utmParameters'))
             if(params.get('utm_content')) {
               utmParameters.content = params.get('utm_content')
               setWithExpiry('utmParameters', JSON.stringify(utmParameters), 2592000000)
              }
              window.sessionStorage.setItem(
                'utmParameters',
                JSON.stringify(utmParameters)
              )
            } else if (params.get('utm_content')) {
              let utm = {
                source: params.get('utm_source') || null,
                medium: params.get('utm_medium') || null,
                campaign: params.get('utm_campaign') || null,
                term: params.get('utm_term') || null,
                content: params.get('utm_content') || null,
              }
      
              window.sessionStorage.setItem('utmParameters', JSON.stringify(utm))
              setWithExpiry('utmParameters', JSON.stringify(utm), 2592000000)
            } else if(typeof Cookies !== 'undefined' && Cookies.get('utmParameters')) {
              window.sessionStorage.setItem(
                'utmParameters',
                Cookies.get('utmParameters')
              )
            }
            
            function setWithExpiry(key, value, ttl) {
              const now = new Date()
              // 'item' is an object which contains the original value
              // as well as the time when it's supposed to expire
      
              const item = {
                value: value,
                expiry: now.getTime() + ttl,
              }
              window.localStorage.setItem(key, JSON.stringify(item))
            }
    
            function getWithExpiry(key) {
              const itemStr = window.localStorage.getItem(key)
              // if the item doesn't exist, return null
              if (!itemStr) {
                return null
              }
      
              const item = JSON.parse(itemStr)
              const now = new Date()
      
              // compare the expiry time of the item with the current time
              if (now.getTime() > item.expiry) {
                // If the item is expired, delete the item from storage
                // and return null
                window.localStorage.removeItem(key)
                return null
              }
              return item.value
            }
          `}
        </Script>
        <Script>
          {`
            // Meta Pixel Code
            !(function (f, b, e, v, n, t, s) {
              if (f.fbq) return
              n = f.fbq = function () {
                n.callMethod
                  ? n.callMethod.apply(n, arguments)
                  : n.queue.push(arguments)
              }
              if (!f._fbq) f._fbq = n
              n.push = n
              n.loaded = !0
              n.version = '2.0'
              n.queue = []
              t = b.createElement(e)
              t.async = !0
              t.src = v
              s = b.getElementsByTagName(e)[0]
              s.parentNode.insertBefore(t, s)
            })(
              window,
              document,
              'script',
              'https://connect.facebook.net/en_US/fbevents.js'
            )
            fbq('init', '303042173204749')
            fbq('track', 'PageView')
            // End Meta Pixel Code
          `}
        </Script>
        <Script type="text/javascript">
          {`
            (function(c,l,a,r,i,t,y){
                c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
                t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
                y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
            })(window, document, "clarity", "script", "ixh05xnjxg");
        `}
        </Script>
      </CookiesProvider>
    );
  };

  return renderWithStore(<Component {...pageProps} />);
}
