import React from 'react';
import Toast from 'react-bootstrap/Toast';
import ToastContainer from 'react-bootstrap/ToastContainer';
import { useSelector, useDispatch } from 'react-redux';
import { updateToastStatus } from '@/store/general';

type tMessageTypes = {
  [key: string]: any;
};

const messageTypes: tMessageTypes = {
  success: {
    iconClass: 'bi-check-circle-fill',
    color: '#74D373',
  },
  danger: {
    iconClass: 'bi-x-circle-fill',
    color: '#EB495F',
  },
  warning: {
    iconClass: 'bi-exclamation-circle-fill',
    color: '#FFC400',
  },
  info: {
    iconClass: 'bi-info-circle-fill',
    color: '#4785E4',
  },
};

function ToastMessage() {
  const dispatch = useDispatch();
  const toastModals = useSelector((state: any) => state.general.toastModals);

  return (
    <ToastContainer className="p-3 toast_position">
      {toastModals && toastModals.length
        ? toastModals.map((toastModal: any, index: any) => (
            <Toast
              onClose={() =>
                dispatch(updateToastStatus({ status: false, index }))
              }
              show={toastModal.showToast}
              key={`toast-${index}`}
              style={{
                borderRadius: '4px',
                overflow: 'hidden',
                border: 'none',
                paddingRight: '6px',
              }}
              delay={toastModal.delay || 5000}
              autohide
            >
              <Toast.Header
                className="justify-content-between"
                style={{ padding: '6px' }}
              >
                <div
                  className="d-flex align-items-center"
                  style={{
                    borderLeft:
                      '3px solid ' + messageTypes[toastModal.type].color,
                    height: '100%',
                    borderRadius: '3px',
                    padding: '6px 0',
                  }}
                >
                  <i
                    className={'bi ' + messageTypes[toastModal.type].iconClass}
                    style={{
                      margin: '0 13px',
                      fontSize: '20px',
                      color: messageTypes[toastModal.type].color,
                    }}
                  ></i>
                  <div className="d-flex flex-column">
                    <p
                      className="me-auto"
                      style={{
                        fontWeight: '600',
                        fontSize: '12px',
                        lineHeight: '15px',
                        margin: '0 0 5px 0',
                        color: '#212121',
                      }}
                    >
                      {toastModal.message.title}
                    </p>
                    <p
                      className="me-auto"
                      style={{
                        fontWeight: '500',
                        fontSize: '12px',
                        lineHeight: '17px',
                        margin: '0',
                        color: '#95959D',
                      }}
                    >
                      {toastModal.message.desc}
                    </p>
                  </div>
                </div>
              </Toast.Header>
            </Toast>
          ))
        : null}
    </ToastContainer>
  );
}

export default ToastMessage;
