import ToastMessage from '@/components/atoms/Toast';
import Header from '@/components/organisms/Header';
import WhitelistHeader from '@/layouts/whitelistHeader';
import style from '@/styles/Header.module.css';
import {
  setPermission,
  setLoggedIn,
  setIsOnboarded,
} from '@/modules/sso/store/sso';
import { useDispatch } from 'react-redux';
import { RepositoryFactory } from '@/apiRepository/RepositoryFactory';
import { useCookies } from 'react-cookie';
const AuthRepository = RepositoryFactory.get('auth');
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { initWebengageUser } from '@/public/js/webengage';
const _ = require('lodash');
import { useRouter } from 'next/router';
import { setIsWhitelist, setWhitelistBrandLogo } from '@/store/whitelist';
import OnboardingModal from '@/modules/onboarding/components/organisms/OnboardingModal';
import { get } from 'lodash';

const allowedUnloggedUrls = [
  '/influencer/',
  '/report/share/',
  '/collection/share',
  '/smart-campaigns/create-campaign?requirement=INFLUENCER_MARKETING',
  '/smart-campaigns/create-campaign?requirement=CONTENT_GENERATION',
  '/link/platform',
];

const getPaid = (contracts: any) => {
  if (contracts) {
    for (const contract of contracts) {
      if (contract.type === 'SAAS') {
        if (contract.plan === 'SAAS') return 'saas';
        else if (contract.plan === 'PAID') return 'enterprise';
      }
    }
  }
  return false;
};

const DefaultLayout = ({ children }: any) => {
  const router = useRouter();
  const { accessToken }: any = router.query;
  const dispatch = useDispatch();
  const [initialised, setInitialised] = useState(false);
  const [overrideOnboardedModal, setOverrideOnboardedModal] = useState(false);
  const [cookies, setCookie, removeCookies] = useCookies(['user']);
  const paid = useSelector((state: any) => state.sso.paid);
  const isOnboarded = useSelector((state: any) => state.sso.isOnboarded);
  const isWhitelist = useSelector((state: any) => state.whitelist.isWhitelist);
  const whitelistBrandLogo = useSelector(
    (state: any) => state.whitelist.whitelistBrandLogo
  );
  const loadingPaidRestrictions = useSelector(
    (state: any) => state.sso.paidRestrictions.loading
  );

  const accessRoutes = useSelector(
    (state: any) => state.whitelist.accessRoutes
  );
  const defaultRoute = useSelector(
    (state: any) => state.whitelist.defaultRoute
  );

  function updateCookie(data: any) {
    // check for domain
    let SUBDOMAIN: any = 'goodcreator.co';
    if (window.location.hostname.match('vidooly')) SUBDOMAIN = 'vidooly.com';
    if (window.location.hostname.match('localhost')) SUBDOMAIN = 'localhost';
    const today = new Date();
    let after30D = new Date();
    after30D.setDate(today.getDate() + 30);

    setCookie('user', data, {
      domain: SUBDOMAIN,
      expires: after30D,
      path: '/',
    });
  }

  const getOnboarding = (contracts: any) => {
    const saasContract = contracts?.find(
      (contract: any) => contract.type === 'SAAS'
    );
    const onboardingStatus = saasContract?.onboardingStatus;
    if (onboardingStatus === 'IN_PROGRESS') dispatch(setIsOnboarded(false));
  };

  useEffect(() => {
    if (window && window.localStorage.getItem('permissions'))
      dispatch(setPermission(window.localStorage.getItem('permissions')));

    const getInitDevice = async () => {
      try {
        const userToken =
          accessToken || (cookies.user && cookies.user.userToken) || null;
        const refreshToken =
          (cookies.user && cookies.user.refreshToken) || null;
        const { data } = await AuthRepository.initDevice({
          userToken: userToken,
          userRefreshToken: refreshToken,
        });
        if (data && data.initDeviceV2 && data.initDeviceV2.loggedIn) {
          initWebengageUser({
            name: _.get(data, 'initDeviceV2.userAccount.name', ''),
            email: _.get(data, 'initDeviceV2.userAccount.email', ''),
            phone: _.get(data, 'initDeviceV2.userAccount.phone', ''),
            webengageUserId: _.get(
              data,
              'initDeviceV2.userAccount.webengageUserId',
              ''
            ),
          });

          dispatch(
            setLoggedIn({
              loggedIn: true,
              paid: getPaid(
                get(
                  data,
                  'initDeviceV2.userAccount.partnerUserProfile.partner.contracts',
                  []
                )
              ),
              partnerUserProfile:
                data.initDeviceV2.userAccount &&
                data.initDeviceV2.userAccount.partnerUserProfile &&
                data.initDeviceV2.userAccount.partnerUserProfile.partner &&
                data.initDeviceV2.userAccount.partnerUserProfile.partner
                  .contracts &&
                data.initDeviceV2.userAccount.partnerUserProfile.partner.contracts.filter(
                  (contract: any) => contract.type === 'SAAS'
                ),
              credsLoginEnabled: data.initDeviceV2.credsLoginEnabled,
            })
          );

          window.sessionStorage.setItem(
            'brandId',
            (data.initDeviceV2.userAccount &&
              data.initDeviceV2.userAccount.brand &&
              data.initDeviceV2.userAccount.brand.id) ||
              null
          );

          if (cookies.user) {
            if (
              cookies.user.userAccount &&
              cookies.user.userAccount.brand &&
              cookies.user.userAccount.brand.id > 0 &&
              data.initDeviceV2.userAccount
            ) {
              cookies.user.userAccount.brand =
                data.initDeviceV2.userAccount.brand;
            }
            updateCookie(cookies.user);
          }
          getOnboarding(
            get(
              data,
              'initDeviceV2.userAccount.partnerUserProfile.partner.contracts',
              []
            )
          );
        } else {
          window.sessionStorage.setItem('brandId', '');
        }

        if (
          data &&
          data.initDeviceV2 &&
          data.initDeviceV2.userToken &&
          data.initDeviceV2.userToken !== userToken
        ) {
          if (cookies.user) {
            cookies.user.userToken = data.initDeviceV2.userToken;
            cookies.user.refreshToken = data.initDeviceV2.userRefreshToken;
            if (
              cookies.user.userAccount &&
              cookies.user.userAccount.brand &&
              cookies.user.userAccount.brand.id > 0 &&
              data.initDeviceV2.userAccount
            ) {
              cookies.user.userAccount.brand =
                data.initDeviceV2.userAccount.brand;
            }
            updateCookie(cookies.user);
            setInitialised(true);
            // window.location.reload();
          } else {
            if (window.sessionStorage.getItem('anonymousToken')) {
              setInitialised(true);
              window.sessionStorage.removeItem('anonymousToken');
            } else {
              updateCookie({
                userToken: data.initDeviceV2.userToken,
                refreshToken: data.initDeviceV2.userRefreshToken,
              });
              window.sessionStorage.setItem('anonymousToken', 'true');
              setInitialised(true);
              // window.location.reload();
            }
          }
        } else {
          setInitialised(true);
        }
      } catch (error) {
        console.log('error');
        console.error(error);
      }
    };

    const isWhitelist =
      process.env.NEXT_PUBLIC_DOMAIN_ENV === 'whitelist' ||
      window.location.hostname.includes('momentum.goodcreator.co');

    dispatch(setIsWhitelist(isWhitelist));

    if (isWhitelist)
      dispatch(
        setWhitelistBrandLogo(
          'https://d22h6s1qvdt49m.cloudfront.net/tr:w-600/static/64805c247318f95df6f13629.png'
        )
      );
    else
      dispatch(
        setWhitelistBrandLogo(
          'https://d22h6s1qvdt49m.cloudfront.net/tr:w-600/static/6488b8ee7318f95df6f16d75.png'
        )
      );

    if (!get(cookies, 'user.userAccount.id', null)) {
      if (
        allowedUnloggedUrls.every(
          (allowedUnloggedUrl) =>
            !window.location.href.includes(allowedUnloggedUrl)
        )
      ) {
        window.location.replace(
          `/sso/login?redirectUrl=${window.location.href}`
        );
        return;
      }
    }
    if (isWhitelist && !accessRoutes.includes(router.route)) {
      window.location.href = defaultRoute;
    } else getInitDevice();

    if (window.location.href.includes('smart-campaigns/create-campaign')) {
      setOverrideOnboardedModal(true);
    }
  }, [dispatch]);

  if (!initialised)
    return (
      <>
        <div
          style={{
            backgroundColor: '#6281bc',
            width: '100%',
            height: '100vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {whitelistBrandLogo ? (
            <img src={whitelistBrandLogo} alt="logo" height={70} />
          ) : (
            <div className="spinner-border" role="status">
              <span className="sr-only"></span>
            </div>
          )}
        </div>
      </>
    );

  return (
    <>
      {isWhitelist ? (
        <div>
          <WhitelistHeader></WhitelistHeader>
          {!isOnboarded &&
            !window.location.href.includes(
              'smart-campaigns/create-campaign'
            ) && <OnboardingModal />}
          {loadingPaidRestrictions === false && (
            <div className={style.whitelist_saas_container}>{children}</div>
          )}
          <ToastMessage />
        </div>
      ) : (
        <div>
          <Header></Header>
          {!isOnboarded &&
            !window.location.href.includes(
              'smart-campaigns/create-campaign'
            ) && <OnboardingModal />}
          {loadingPaidRestrictions === false && (
            <div className={style.saas_container}>{children}</div>
          )}
          <ToastMessage />
        </div>
      )}
    </>
  );
};

export default DefaultLayout;
