import { createSlice } from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';

const initialState = {
  lastCollection: null,
  latestCollection: null,
  searchPhrase: '',
  appliedFilters: [],
};

export const collectionsSlice = createSlice({
  name: 'collections',
  initialState,
  reducers: {
    setLatestCollection(state, action) {
      state.latestCollection = action.payload;
    },
    setLastCollection(state, action) {
      state.lastCollection = action.payload;
    },
    updateLatestCollection(state, action) {
      state.lastCollection = state.latestCollection
        ? { ...state.latestCollection }
        : null;
      state.latestCollection = action.payload;
    },
    updateSearchPhrase(state, action) {
      state.searchPhrase = action.payload;
    },
    updateFilters(state, action) {
      if (action.payload.type == 'reset') state.appliedFilters = [];
      else {
        let formatedFilters = [];
        formatedFilters = formatedFilters.concat(action.payload.formatedFilter);

        state.appliedFilters = formatedFilters;
      }
    },
    // Special reducer for hydrating the state
    extraReducers: {
      [HYDRATE]: (state, action) => {
        return {
          ...state,
          ...action.payload.collections,
        };
      },
    },
  },
});

export const {
  setLatestCollection,
  setLastCollection,
  updateLatestCollection,
  updateSearchPhrase,
  updateFilters,
} = collectionsSlice.actions;

export default collectionsSlice.reducer;
