import style from '@/styles/ProUpgradeModal.module.css';
import React from 'react';

const SuccessModal = () => {
  return (
    <>
      <div
        className="modal fade"
        id="ThankYouPopup"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content" style={{ borderRadius: '8px' }}>
            <i
              className={`bi bi-x ${style.close_modal}`}
              data-bs-dismiss="modal"
            ></i>
            <div className="modal-body d-flex flex-column align-items-center p-5">
              <img
                src="https://firebasestorage.googleapis.com/v0/b/winkl-1095.appspot.com/o/gcc-website%2Fimages%2FThank%20you-108.svg?alt=media&token=ca6c6db3-0314-4f4d-b870-aa9f2d18e240"
                alt=""
                style={{ width: '500px' }}
              />
              <p
                className="text-center"
                style={{
                  fontSize: '21px',
                  color: '#6281BC',
                  fontWeight: 700,
                  marginBottom: '10px',
                }}
              >
                Thank you for your interest!
              </p>
              <p
                className="text-center"
                style={{
                  fontSize: '16px',
                  fontWeight: 500,
                  marginBottom: '10px',
                  color: '#A6A6A6',
                }}
              >
                We are delighted to hear about your interest. Experts from our
                team will reach out to you soon
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default SuccessModal;
