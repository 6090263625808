import { setModalType } from '@/modules/sso/store/sso';
import { upgradePlanClicked } from '@/public/js/webengage';
import style from '@/styles/ProUpgrade.module.css';
import { useCookies } from 'react-cookie';
import { useDispatch, useSelector } from 'react-redux';

const ProUpgrade = () => {
  const [cookies] = useCookies();
  const dispatch = useDispatch();
  const paid = useSelector((state: any) => state.sso.paid);
  const loggedIn = useSelector((state: any) => state.sso.loggedIn);

  const handleClickEvent = () => {
    const payload: any = {
      userId:
        cookies.user && cookies.user.userAccount
          ? cookies.user.userAccount.id
          : null,
      planType: paid ? 'Paid plan' : 'Free plan',
      brandId:
        cookies.user &&
        cookies.user.userAccount &&
        cookies.user.userAccount.brand
          ? cookies.user.userAccount.brand.id
          : null,
    };

    upgradePlanClicked(payload);
    if (!loggedIn) {
      const redirectUrl = window.location.href;
      window.location.href = `${window.location.origin}/sso/login?redirectUrl=${redirectUrl}`;
    }
  };
  return (
    <>
      <span className={style.chicklet}>
        <b>Pro</b>
        <span className={style.upgrade_popop}>
          Only available on Pro or higher plans
          <br />
          <button
            className={style.upgrade_btn}
            data-bs-toggle={!loggedIn ? null : 'modal'}
            data-bs-target={!loggedIn ? '' : '#ModalProUpgrade'}
            onClick={handleClickEvent}
          >
            Upgrade Now
          </button>
        </span>
      </span>
    </>
  );
};

export default ProUpgrade;
