import { useDispatch } from 'react-redux';
import styles from '../../styles/OnboardingModal.module.css';
import { updateToastStatus } from '@/store/general';

const options = ['Brand', 'Agency', 'Freelancer'];

const QuestionsPage1 = ({ setPageNo, data, setData, webengageEvent }: any) => {
  const dispatch = useDispatch();

  return (
    <div>
      <div className={styles.text_container}>
        <div className={styles.title}>
          What best describes you or your organisation?
        </div>
        <div className={styles.options}>
          {options?.map((option: string, idx: number) => {
            return (
              <div className={styles.option} key={`option${idx}`}>
                <input
                  type="radio"
                  style={{
                    width: 24,
                    height: 24,
                    cursor: 'pointer',
                  }}
                  checked={option === data.roleInOrg}
                  onChange={() =>
                    setData({
                      ...data,
                      roleInOrg: option,
                    })
                  }
                />
                <div className={styles.option_name}>{option}</div>
              </div>
            );
          })}
        </div>
      </div>
      <div className={styles.btns}>
        <div
          className={
            !data.roleInOrg ? styles.next_btn_disabled : styles.next_btn
          }
          onClick={() => {
            if (!data.roleInOrg) {
              dispatch(
                updateToastStatus({
                  status: true,
                  type: 'danger',
                  title: 'Error',
                  desc: 'Please choose an option!',
                })
              );
              return;
            }
            setPageNo(2);
            webengageEvent({
              eventName: 'VS - Organisation Type Selected',
              value: { 'Organisation type': data.roleInOrg },
            });
          }}
        >
          Next
        </div>
      </div>
    </div>
  );
};

export default QuestionsPage1;
